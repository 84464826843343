import { Pipe, PipeTransform } from '@angular/core';
import { ISceneDefinitionDocumentSingleVersionResponse, ISceneObject } from 'projects/digital-twin/src/app/interfaces/api-response.interface';
@Pipe({
  name: 'associateButton'
})
export class AssociateButtonPipe implements PipeTransform {
  transform(componentId: string = '', currentSceneDefinitionDoc: ISceneDefinitionDocumentSingleVersionResponse): boolean {
    const sceneObject: ISceneObject[] = [...currentSceneDefinitionDoc?.sceneObjects] || [];
    const filteredSceneObject: ISceneObject | undefined = sceneObject.find(element => element?.properties['component_reference'] === componentId);
    return !filteredSceneObject?._id;
  }
}
