<div class="property-container" *ngIf="diplayModal">
    <div class="property-container-layout">
      <div class="property-container-layout-header">
        <div class="d-flex justify-content-between">
          <span class="property-container-layout-header-title">
           SELECT MEASUREMENT
          </span>
          <span class="property-container-layout-header-close" (click)="onCancelClick()">
            <img [src]="'/icon/close.svg' | assetUrl" alt="round">
          </span>
        </div>
        <div class="property-container-layout-header-divider"></div>
      </div>
      <div class="property-container-layout-content">
        <ul class="item-list">
          @for(measurementType of measurementTypes; track measurementType._id) {
            <li class="d-flex align-items-center justify-content-between item" [ngClass]="{'active': selectedMeasurementType && measurementType._id === selectedMeasurementType._id}"  (click)="onSelectType(measurementType)">
              {{measurementType?.name || ''}}
            </li>
          }
          </ul>
      </div>
      <div class="property-container-layout-footer">
        <div class="property-container-layout-footer-buttons-groups d-flex align-items-end justify-content-between">
          <button type="button" class="btn custom-prop-btn" (click)="onCancelClick()">
            {{ propertyModalConfig?.cancelBtnText || 'Cancel'}}
          </button>
          <button type="button" class="btn add-btn" (click)="onConfirmClick()" >
            {{propertyModalConfig?.confirmBtnText || 'Save'}}
          </button>
        </div>
      </div>
    </div>
  </div>
  