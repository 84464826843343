import { Injectable } from '@angular/core';
import { IDataResourceRecord } from 'projects/digital-twin/src/app/interfaces/api-response.interface';
import { IMAGE_SORTING_MODE } from 'projects/digital-twin/src/app/constants';


@Injectable({
  providedIn: 'root'
})

export class ImageSortingService {
  //SortImages
  sortImages(imageDataResourceEntities: IDataResourceRecord[], sortingMode: string): IDataResourceRecord[] {
    if (sortingMode === IMAGE_SORTING_MODE.SPIN_VIEW) {
      imageDataResourceEntities = this.sortSpinView(imageDataResourceEntities);
    } else if (sortingMode === IMAGE_SORTING_MODE.SIDE_VIEW) {
      imageDataResourceEntities = this.sortSideView(imageDataResourceEntities);
    }
    return imageDataResourceEntities;
  }

  //sortSideView
  private sortSideView(imageDataResourceEntities: IDataResourceRecord[]): IDataResourceRecord[] {
    imageDataResourceEntities = this.sortByCreationDate(imageDataResourceEntities);
    if (
      imageDataResourceEntities[0]?.metadata?.exif?.absoluteAltitudeMeters <
      imageDataResourceEntities[imageDataResourceEntities.length - 1]?.metadata?.exif?.absoluteAltitudeMeters
    ) {
      imageDataResourceEntities.reverse();
    }
    return imageDataResourceEntities;
  }

  //sortSpinView
  private sortSpinView(imageDataResourceEntities: IDataResourceRecord[]): IDataResourceRecord[] {
    imageDataResourceEntities = this.sortByCreationDate(imageDataResourceEntities);
    let sortedImgs;
    if (this.isRotationCounterClockwise(imageDataResourceEntities)) {
      sortedImgs = this.counterClockwiseRotationTowerView(imageDataResourceEntities);
    } else {
      sortedImgs = this.clockwiseRotationTowerView(imageDataResourceEntities);
    }
    return sortedImgs;
  }

  //truncateTowerViewToSingleOrbit
  private truncateTowerViewToSingleOrbit(imageDataResourceEntities: IDataResourceRecord[]): IDataResourceRecord[] {
    let firstImgAngle = this.yawAngleDegrees(imageDataResourceEntities[0]?.metadata?.exif?.gimbalYawDegree || null);
    let minAngleIdx = -1;
    let minAngle = IMAGE_SORTING_MODE.GIMBAL_YAW_DEGREE_180;
    for (let [currImgIdx, currImg] of imageDataResourceEntities
      .slice(Math.floor(imageDataResourceEntities.length / 3))
      .entries()) {
      let curImgAngle = this.yawAngleDegrees(currImg?.metadata?.exif?.gimbalYawDegree || null);
      let angleDiff = Math.abs(curImgAngle - firstImgAngle);
      if (angleDiff > IMAGE_SORTING_MODE.GIMBAL_YAW_DEGREE_180) {
        angleDiff = IMAGE_SORTING_MODE.GIMBAL_YAW_DEGREE_360 - angleDiff;
      }
      if (angleDiff < minAngle) {
        minAngleIdx = currImgIdx;
        minAngle = angleDiff;
      }
    }
    if (minAngleIdx === -1 || minAngleIdx === 0) {
      return imageDataResourceEntities;
    }
    return imageDataResourceEntities.slice(0, minAngleIdx + Math.floor(imageDataResourceEntities.length / 3) + 1);
  }

  //counterClockwiseRotationTowerView
  private counterClockwiseRotationTowerView(imageDataResourceEntities: IDataResourceRecord[]): IDataResourceRecord[] {
    imageDataResourceEntities = this.truncateTowerViewToSingleOrbit(imageDataResourceEntities);
    let sortedImgs = [imageDataResourceEntities[0]];
    let prevImgToCompare = imageDataResourceEntities[0];
    for (let [prevImgIdx, currImg] of imageDataResourceEntities.slice(1).entries()) {
      let prevImg = imageDataResourceEntities[prevImgIdx];
      if (this.isImageCounterClockwise(currImg, prevImgToCompare)) {
        sortedImgs.push(currImg);
        prevImgToCompare = currImg;
      } else {
        prevImgToCompare = prevImg;
      }
    }
    return sortedImgs;
  }

  //clockwiseRotationTowerView
  private clockwiseRotationTowerView(imageDataResourceEntities: IDataResourceRecord[]): IDataResourceRecord[] {
    imageDataResourceEntities = this.truncateTowerViewToSingleOrbit(imageDataResourceEntities);
    let sortedImgs = [imageDataResourceEntities[0]];
    let prevImgToCompare = imageDataResourceEntities[0];
    for (let [prevImgIdx, currImg] of imageDataResourceEntities.slice(1).entries()) {
      let prevImg = imageDataResourceEntities[prevImgIdx];
      if (this.isImageClockwise(currImg, prevImgToCompare)) {
        sortedImgs.push(currImg);
        prevImgToCompare = currImg;
      } else {
        prevImgToCompare = prevImg;
      }
    }
    return sortedImgs;
  }

  //isRotationCounterClockwise
  private isRotationCounterClockwise(imageDataResourceEntities: IDataResourceRecord[]): boolean {
    let compareImgs = imageDataResourceEntities.filter((_, imgIdx) => imgIdx % Math.floor(imageDataResourceEntities.length / 8) === 0);
    let counterClockwiseCount = 0;
    let clockWiseCount = 0;
    for (let [prevIdx, prevImg] of compareImgs.slice(0, compareImgs.length - 1).entries()) {
      let curImg = compareImgs[prevIdx + 1];
      if (this.isImageCounterClockwise(curImg, prevImg)) {
        counterClockwiseCount += 1;
      } else if (this.isImageClockwise(curImg, prevImg)) {
        clockWiseCount += 1;
      }
    }
    if (counterClockwiseCount > clockWiseCount) {
      return true;
    } else {
      return false;
    }
  }

  //isImageCounterClockwise
  private isImageCounterClockwise(curr: IDataResourceRecord, prev: IDataResourceRecord): boolean {
    if (this.yawAngleDegrees(curr?.metadata?.exif?.gimbalYawDegree) > this.yawAngleDegrees(prev?.metadata?.exif?.gimbalYawDegree)) return true;
    if (
      this.yawAngleDegrees(curr?.metadata?.exif?.gimbalYawDegree) >= IMAGE_SORTING_MODE.GIMBAL_YAW_DEGREE_0 &&
      this.yawAngleDegrees(curr?.metadata?.exif?.gimbalYawDegree) <= IMAGE_SORTING_MODE.GIMBAL_YAW_DEGREE_90 &&
      this.yawAngleDegrees(prev?.metadata?.exif?.gimbalYawDegree) >= IMAGE_SORTING_MODE.GIMBAL_YAW_DEGREE_270 &&
      this.yawAngleDegrees(prev?.metadata?.exif?.gimbalYawDegree) <= IMAGE_SORTING_MODE.GIMBAL_YAW_DEGREE_360
    ) {
      return true;
    }
    return false;
  }

  //isImageClockwise
  private isImageClockwise(curr: IDataResourceRecord, prev: IDataResourceRecord): boolean {
    if (this.yawAngleDegrees(curr?.metadata?.exif?.gimbalYawDegree) < this.yawAngleDegrees(prev?.metadata?.exif?.gimbalYawDegree)) return true;
    if (
      this.yawAngleDegrees(curr?.metadata?.exif?.gimbalYawDegree) >= IMAGE_SORTING_MODE.GIMBAL_YAW_DEGREE_270 &&
      this.yawAngleDegrees(curr?.metadata?.exif?.gimbalYawDegree) <= IMAGE_SORTING_MODE.GIMBAL_YAW_DEGREE_360 &&
      this.yawAngleDegrees(curr?.metadata?.exif?.gimbalYawDegree) >= IMAGE_SORTING_MODE.GIMBAL_YAW_DEGREE_0 &&
      this.yawAngleDegrees(curr?.metadata?.exif?.gimbalYawDegree) <= IMAGE_SORTING_MODE.GIMBAL_YAW_DEGREE_90
    ) {
      return true;
    }
    return false;
  }

  //sortByCreationDate
  private sortByCreationDate(imageDataResourceEntities: IDataResourceRecord[]): IDataResourceRecord[] {
    imageDataResourceEntities.sort((dataResource: any, nextDataResource: any): any => {
      const dataResourceOneDateTime: string = dataResource?.metadata?.exif?.dateTimeOriginal || '';
      const dataResourceSecondDateTime: string = nextDataResource?.metadata?.exif?.dateTimeOriginal || '';
      if (dataResourceOneDateTime && dataResourceSecondDateTime) {
        const date1 = new Date(dataResourceOneDateTime).getTime();
        const date2 = new Date(dataResourceSecondDateTime).getTime();
        return date1 - date2;
      }
      else {
        return '';
      }
    });
    return imageDataResourceEntities;
  }

  //GimbleYawDegrees
  private yawAngleDegrees(gimbal_yaw_degree: any | null) {
    if (gimbal_yaw_degree === null) {
      return gimbal_yaw_degree
    } else if (gimbal_yaw_degree < 0) {
      return IMAGE_SORTING_MODE.GIMBAL_YAW_DEGREE_360 + gimbal_yaw_degree
    } else {
      return gimbal_yaw_degree
    }
  }
}



