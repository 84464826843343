import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { IDonutChartConfig, IDonutChartOptions } from '../../../interfaces/charts.interface';
import { GLOBAL_COLOR_CODE_VARIABLES } from 'projects/digital-twin/src/app/constants/canvas.const';
@Component({
  selector: 'app-custom-donut-chart',
  templateUrl: './custom-donut-chart.component.html',
  styleUrls: ['./custom-donut-chart.component.scss']
})
export class CustomDonutChartComponent implements OnInit, OnChanges {
  /* #region variable initialization/declaration */
  chartHeight: string = "290px";
  chartWidth: string = "290px";
  chartOptions: Partial<IDonutChartOptions>;
  /* #endregion variable initialization/declaration */

  /* #region input/ouput/viewChild decorators */
  @Input() chartConfig!: IDonutChartConfig;
  @ViewChild("chart") chart!: ChartComponent;
  /* #endregion input/ouput/viewChild decorators */

  constructor() {
    // https://stackoverflow.com/questions/66001649/apex-charts-donut-chart-padding-between-series-items-in-plot
    this.chartOptions = {
      chart: {
        type: 'donut',
        height: this.chartHeight,
        width: this.chartWidth,
      },
      legend: {
        show: true,
        fontWeight: 400,
        position: 'right',
        fontSize: '10.7389px',
        lineHeight: '13px',
        letterSpacing: '0.357962px',
        marginTop: 20, // set font size for legend
        labels: {
          colors: GLOBAL_COLOR_CODE_VARIABLES.CV_WHITE,
        },
        itemMargin: {
          horizontal: 0,
          vertical: 4,
        },
        onItemHover: {
          highlightDataSeries: false // Disable hover behavior
        }
      },
      fill: {
        type: 'solid',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 0,
      },
    };
  }

  private plotOptionConfig(percentStatus: boolean = false) {
    if (percentStatus) {
      return {
        pie: {
          donut: {
            size: '70%',
            dataLabels: {
              enabled: false,
            },
            labels: {
              show: true,
              name: {
                show: false,
                offsetY: 38,
                formatter: () => 'Completed',
              },
              value: {
                show: true,
                fontSize: '30px',
                fontWeight: 500,
                color: GLOBAL_COLOR_CODE_VARIABLES.CV_WHITE,
                // offsetY: -30,
              },
              total: {
                show: true,
                fontSize: '15px', // set font size for total label
                label: 'Images', // set label for total
                color: GLOBAL_COLOR_CODE_VARIABLES.CV_WHITE,
                formatter: (w: any): any => {
                  const total = w.globals.seriesTotals.reduce(
                    (a: any, b: any): any => a + b,
                    0,
                  )
                  return `${total}%`
                },
              },
            },
          },
        },
      }
    } else {
      return {
        pie: {
          // startAngle: 10,
          donut: {
            size: '70%',
            labels: {
              show: true,
              name: {
                show: false,
                offsetY: 38,
                formatter: () => 'Completed',
              },
              value: {
                show: true,
                fontSize: '30px',
                fontWeight: 500,
                color: GLOBAL_COLOR_CODE_VARIABLES.CV_WHITE,
                // offsetY: -30,
              },
              total: {
                show: true,
                fontSize: '15px', // set font size for total label
                label: 'Images', // set label for total
                color: GLOBAL_COLOR_CODE_VARIABLES.CV_WHITE,
              },
            },
          },
        },
      }
    }
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chartConfig'] != undefined) {
      let chartConfig: any = { ...changes['chartConfig'].currentValue } || {};
      let seriesOptions: any[] = chartConfig?.seriesOptions;
      this.chartOptions['series'] = this.fnIsArrayhasLength(seriesOptions) ? seriesOptions.map(ele => { return ele.series }) : null;
      this.chartOptions['labels'] = this.fnIsArrayhasLength(seriesOptions) ? seriesOptions.map(ele => { return ele.label }) : '';
      this.chartOptions['colors'] = this.fnIsArrayhasLength(seriesOptions) ? seriesOptions.map(ele => { return ele.color }) : '';
      this.chartOptions['fill']['colors'] = this.fnIsArrayhasLength(seriesOptions) ? seriesOptions.map(ele => { return ele.color }) : '';
      this.chartOptions['plotOptions'] = this.plotOptionConfig(chartConfig?.isPlotTotalInPercentage || false);
      if (chartConfig?.chartHeight && chartConfig?.chartWidth) {
        this.chartOptions['chart']['height'] = chartConfig?.chartHeight;
        this.chartOptions['chart']['width'] = chartConfig?.chartWidth;
      }
    }
  }

  fnIsArrayhasLength(tempValue: any[] = []) {
    return (tempValue && tempValue.length)
  }
}
