<div class="component-container" *ngIf="diplayModal">
  <div class="component-container-layout">
    <div class="component-container-layout-header">
      <div class="d-flex justify-content-between">
        <span class="component-container-layout-header-title">
          Component Type
        </span>
        <span class="component-container-layout-header-close" (click)="onCancelClick()">
          <img [src]="'/icon/close.svg' | assetUrl" alt="round">
        </span>
      </div>
      <div class="component-container-layout-header-divider"></div>
    </div>
    <div class="component-container-layout-content">
      <div class="component-container-layout-content-list">
        <ul *ngFor="let componentType of componentTypeList">
          <li class="d-flex align-items-center justify-content-between">
            <span>
              <h6 class="component-name">{{componentType?.type || ''}}</h6>
            </span>
            <span>
              <button type="button" class="btn select-btn" (click)="onSelectType(componentType)">
                Select
              </button>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
  