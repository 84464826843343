export const AMPLITEL_ROUTES = [
  "/canvas/amplitel/digital-twin",
  "/canvas/amplitel/from-above",
  "/canvas/amplitel/los",
  "/canvas/amplitel/side-view",
  "/canvas/amplitel/tower-view",
]
export const AMPLITEL_CUSTOMER_PORTAL = {
  CUSTOMER_ID: "65d70c4816f4d3dbd7ca941e",
  STRUCTURE_NAME: "Structure",
  DEFAULT_PREFIX: 'Unknown'
}