import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IConfirmDialogConfig } from 'projects/digital-twin/src/app/interfaces/canvas.interface';

@Component({
  selector: 'app-custom-confirm-dialog',
  templateUrl: './custom-confirm-dialog.component.html',
  styleUrls: ['./custom-confirm-dialog.component.scss']
})
export class CustomConfirmDialogComponent {
  diplayModal: boolean = false;
  @Input() propertyModalConfig: IConfirmDialogConfig;
  @Output() confirmAction: EventEmitter<boolean> = new EventEmitter();
  
  openModal() {
    this.diplayModal = true;
  }

  closeModal() {
    this.diplayModal = false;
  }

  onConfirmClick() {
    this.confirmAction.emit(true);
  }
  onCancelClick() {
    this.confirmAction.emit(false);
  }
}
