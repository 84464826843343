import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ICommonProgressbar } from 'projects/digital-twin/src/app/interfaces';
import { Nullable } from 'projects/digital-twin/src/app/shared/types';
@Component({
  selector: 'app-custom-progress-bar',
  templateUrl: './custom-progress-bar.component.html',
  styleUrls: ['./custom-progress-bar.component.scss']
})
export class CustomProgressBarComponent implements OnInit, OnChanges {
  progressName: string = '';
  progressPercentage: number = 0;
  
  /* #region input/ouput/viewChild decorators */
  @Input() showOnlyProgressBar = false;
  @Input() commonProgressbarObject!: Nullable<ICommonProgressbar>;
  /* #endregion input/ouput/viewChild decorators */

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['commonProgressbarObject'] != undefined) {
      let commonProgressbarObject: ICommonProgressbar = { ...changes['commonProgressbarObject'].currentValue } || {};
      this.progressName = commonProgressbarObject?.progressName || '';
      this.progressPercentage = commonProgressbarObject?.progressPercentage || 0;
    }
  }
}
