const config = {
  header: {
    show: true,
    children: [
      { name: 'home', show: true },
      { name: 'launchpad', show: true },
      { name: 'search', show: true },
      { name: 'notification', show: true },
      { name: 'profile', show: true },
    ],
  },
  sidebar: {
    show: true,
    children: [
      { icon: 'site', sidebarName: 'Sites', show: true },
      { icon: 'mapsetting', sidebarName: 'Map Setting', show: true },
      { icon: 'overview', sidebarName: 'Overview', show: true },
      { icon: 'annotations', sidebarName: 'Annotations', show: true },
      { icon: 'equipment', sidebarName: 'Equipment', show: true },
      { icon: 'mission', sidebarName: 'Mission', show: true },
    ]
  },
  footer: {
    show: false,
    children: [
      // left
      { name: 'elevation', show: true },
      { name: 'celcius', show: true },
      { name: 'wind', show: true },
      { name: 'cloud', show: true },
      { name: 'moisture', show: true },
      // right
      { name: 'latlng', show: true },
      { name: 'location', show: true },
      { name: 'scale', show: true },
      { name: 'airspace', show: true },
    ],
  }
}

const appName = 'canvas' // example for canvas

localStorage.setItem('config-' + appName, JSON.stringify(config))