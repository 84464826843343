import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDonutChartComponent } from './components/custom-donut-chart/custom-donut-chart.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CustomImageViewerComponent } from './components/custom-image-viewer/custom-image-viewer.component';
import { CustomBarChartComponent } from './components/custom-bar-chart/custom-bar-chart.component';
import { CustomProgressBarComponent } from './components/custom-progress-bar/custom-progress-bar.component';
import { SearchFilterPipe } from 'projects/digital-twin/src/app/shared/pipes/search-filter.pipe';
import { CustomNotificationComponent } from './components/custom-notification/custom-notification.component';
import { ToasterNotifyComponent } from './components/toaster-notify/toaster-notify.component';
import { CustomRangeSliderComponent } from './components/custom-range-slider/custom-range-slider.component';
import { permissionGuardDirective } from '../directives/permission.directive';
import { CustomValidatorModule } from './custom-validator/custom-validator.module';
import { AssociateButtonPipe } from './pipes/associate-button.pipe';
import { AssetUrlPipe } from '../asset-url.pipe';
import { TitleCaseConvertorPipe } from './pipes/titlecase-convertor.pipe';
import { CustomPropertyModalComponent } from './components/custom-property-modal/custom-property-modal.component';
import { NotfoundiframecomponentComponent } from './components/notfoundiframecomponent/notfoundiframecomponent.component';
import { HttpLoaderComponent } from './components/http-loader/http-loader.component';
import { CustomConfirmDialogComponent } from './components/custom-confirm-dialog/custom-confirm-dialog.component';
import { CustomComponentTypeModalComponent } from './components/custom-component-type-modal/custom-component-type-modal.component';
import { TrimFilterPipe } from 'projects/digital-twin/src/app/shared/pipes/trim-filter.pipe';
import { CustomCompassComponent } from './components/custom-compass/custom-compass.component';
import { FormsModule } from '@angular/forms';
import { CustomComponentDeleteModalComponent } from './components/custom-component-delete-modal/custom-component-delete-modal.component';
import { CustomMeasurementTypeModalComponent } from './components/custom-measurement-type-modal/custom-measurement-type-modal.component';
import { MessageModalComponent } from 'projects/field-templates/src/app/shared/components/message-modal/message-modal.component';
import { CustomDropdownComponent } from './components/custom-dropdown/dropdown.component';
import { TitlecaseFromDelimitersPipe } from './pipes/titlecase-from-delimiters.pipe';

const CHARTS: any[] = [
  CustomDonutChartComponent,
  CustomBarChartComponent,
]

const COMPONENTS: any[] = [
  CustomProgressBarComponent,
  CustomImageViewerComponent,
  HttpLoaderComponent,
  CustomNotificationComponent,
  ToasterNotifyComponent,
  CustomRangeSliderComponent,
  NotfoundiframecomponentComponent,
  CustomConfirmDialogComponent,
  CustomComponentTypeModalComponent,
  CustomComponentDeleteModalComponent,
  CustomMeasurementTypeModalComponent,
]

const PIPES: any[] = [
  SearchFilterPipe,
  AssociateButtonPipe,
  AssetUrlPipe,
  TitleCaseConvertorPipe,
  TitlecaseFromDelimitersPipe,
]

const Directives: any[] = [
]

const OtherModules: any[] = [
  CustomValidatorModule
]

@NgModule({
  declarations: [
    ...CHARTS,
    ...COMPONENTS,
    ...PIPES,
    ...Directives,
    CustomCompassComponent,
  ],
  imports: [
    CommonModule,
    NgApexchartsModule,
    FormsModule,
    CustomPropertyModalComponent,
    MessageModalComponent,
    TrimFilterPipe,
    permissionGuardDirective,
    CustomDropdownComponent,
    ...OtherModules
  ],
  exports: [
    permissionGuardDirective,
    ...CHARTS,
    ...COMPONENTS,
    ...PIPES,
    ...Directives,
    ...OtherModules
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
