import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from "@angular/core";
import { AssetUrlGlobePipe } from "projects/annotation-2d/src/app/asset-url-globe.pipe";
@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  standalone: true,
  imports:[CommonModule, AssetUrlGlobePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CustomDropdownComponent{
  @Input() options: string[]
  @Input() selected: string[]
  @Input() placeholder = ''
  @Input() label: string
  @Input() multiple = false
  @Input() allowCreation = false;
  @Input() selectOnly = false
  @Output() onSelect = new EventEmitter<string>();
  @Output() onRemoveTag = new EventEmitter<string>();
  @Output() filterData = new EventEmitter<string>();
  @Output() onCreate = new EventEmitter<boolean>();
  showDropdown = false;
  selectedMenuItem = '';
  showSelectedMenu = false;
  selectedControl: string;
  clickListener: () => void;
  @ViewChild('metaFieldText') metaFieldText: ElementRef;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
  
  ngOnDestroy() {
    if (this.clickListener) {
      this.clickListener();
    }
  }
  
  toggleDropdown(){
    this.showDropdown = !this.showDropdown
    if(this.showDropdown){
      this.addOutsideClickListener();
    }
  }

  selectOption(option: string): void {
    if(this.multiple && this.selected.includes(option)){
      this.onRemoveTag.emit(option);
    }else{
      this.onSelect.emit(option);
    }
    if(!this.multiple)this.showDropdown = false;
  }
    
  removeSelected(value:string): void {
    this.onRemoveTag.emit(value);
  }

  filterOptions(value:string): void {
    if(value.length){
      this.filterData.emit(value);
    }else{
      this.filterData.emit("");
      this.onSelect.emit("");
    }
    this.showDropdown = true;
  }

  add(){
    this.onCreate.emit(true)
  }

  onKeyEnter(selectedValue:string){
    if(selectedValue.length){
      this.selectOption(selectedValue);
      this.filterData.emit("");
    }
  }

  addOutsideClickListener(): void {
    this.clickListener = this.renderer.listen('document', 'click', (event: MouseEvent) => {
      if (!this.elementRef.nativeElement.contains(event.target) &&  this.showDropdown) {
        this.showDropdown = false;
        this.showSelectedMenu = false;
      }
    });
  }

  openSubMenu(event:any,item: string) {
    event.stopPropagation()
    this.selectedMenuItem = item;
    this.showSelectedMenu = !this.showSelectedMenu;
    if (!this.showSelectedMenu) {
      this.selectedMenuItem = '';
    }
  }
}