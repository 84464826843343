import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IConfirmDialogConfig } from '../../../interfaces';
import { LabelLookupService } from '../../../services';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-custom-measurement-type-modal',
  templateUrl: './custom-measurement-type-modal.component.html',
  styleUrl: './custom-measurement-type-modal.component.scss'
})
export class CustomMeasurementTypeModalComponent {
  diplayModal: boolean = false;
  @Input() propertyModalConfig: IConfirmDialogConfig;
  @Output() confirmAction: EventEmitter<any> = new EventEmitter();
  private unsubscribeMeasuremntTypes: Subject<void> = new Subject<void>();
  measurementTypes: any = [];
  selectedMeasurementType: any;
  constructor(private labelLookupService: LabelLookupService ) {
    this.getMeasturementTypes();
  }
  getMeasturementTypes() {
    this.labelLookupService.measurementTypes$.pipe(takeUntil(this.unsubscribeMeasuremntTypes)).subscribe({
      next: (data) => {
        this.measurementTypes = [...data ];
      },
      error: (err: any) => { console.error(err); }
    });
  } 

  openModal() {
    this.diplayModal = true;
  }

  closeModal() {
    this.diplayModal = false;
    this.selectedMeasurementType = null;
  }

  onConfirmClick() {
    this.confirmAction.emit({status: true, data: this.selectedMeasurementType});
  }
  onCancelClick() {
    this.confirmAction.emit({status: false});
  }
  ngOnDestroy() {
    this.unsubscribeMeasuremntTypes.next();
    this.unsubscribeMeasuremntTypes.complete();
  }
  onSelectType(type: any) {
    this.selectedMeasurementType = type !== this.selectedMeasurementType ? type : null;
  }
}
