import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoaderService } from 'projects/digital-twin/src/app/services/loader.service';

@Injectable({
  providedIn: 'root',
})
export class IframeHttpInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authKey: string | null = localStorage.getItem("authKey");
    let Workspaceid: string | null = localStorage.getItem("workspaceId");
    req = req.clone({
      setHeaders: {
        Authorization: `${authKey}`,
        Workspaceid: Workspaceid ? Workspaceid : ""
      },
    });
    this.showLoader();
    return next.handle(req).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.hideLoader();
          }
        },
        error: (error) => {
          this.hideLoader();
        },
      })
    );
  }

  private showLoader(): void {
    this.loaderService.show();
  }
  private hideLoader(): void {
    this.loaderService.hide();
  }
}
