<div class="chart-wrap my-1">
  <div class="donut-chart">
    <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [labels]="chartOptions.labels"
      [legend]="chartOptions.legend" [fill]="chartOptions.fill" [colors]="chartOptions.colors"
      [dataLabels]="chartOptions.dataLabels" [stroke]="chartOptions.stroke" [plotOptions]="chartOptions.plotOptions">
    </apx-chart>
  </div>
  <div class="list-wrap">
    <ul>
      <li *ngFor="let item of chartOptions.series">
        {{ item }}
      </li>
    </ul>
  </div>
</div>
