export interface IDonutChartOptions {
    chart: any;
    series: any;
    labels: any;
    legend: any;
    fill: any;
    colors: any;
    dataLabels: any;
    stroke: any;
    plotOptions: any;
}

export interface IDonutChartConfig {
    seriesOptions: Array<IDonutSeriesOptions>;
    isPlotTotalInPercentage: boolean;
    chartHeight?: string;
    chartWidth?: string;
}

export interface IDonutSeriesOptions {
    series: number;
    label: string;
    color: string;
}

export interface IBarChartOptions {
    yaxis: any;
    xaxis: any;
    dataLabels: any;
    grid: any;
    legend: any;
    series: any;
    chart: any;
    plotOptions: any;
    fill: any;
};

export interface IBarChartConfig {
    seriesOptions: Array<IBarSeriesOptions>;
    listItems: Array<IBarListItems>;
}

export interface IBarSeriesOptions {
    name: string;
    data: Array<number>;
}

export interface IBarListItems {
    componet: string;
    num: number;
    percent: string;
    img: string;
    backgroundColor: string;
}
