import { EnvironmentService } from 'projects/shared-library/src/lib/services/environment.service';

const environmentService = new EnvironmentService();
const apiUrl = environmentService.getApiUrl();

export class Routes {
  static baseAddress = apiUrl;

  // =============================== User Management =============================== //
  static GET_MEMBERS = Routes.baseAddress + '/workspace/members';

  // =============================== Aws Management =============================== //
  static GET_AWS_DATA_CREDENTIALS =
    Routes.baseAddress + '/data-resource/data-resource/credentials';

  // =============================== Mission =============================== //
  static GET_MISSION = Routes.baseAddress + '/mission/data-proc-only/missions';

  // =============================== Workflow =============================== //
  static WORKFLOW_RUNS = Routes.baseAddress + '/workflows/workflow-runs';
  static WORKFLOW_DEFS = Routes.baseAddress + '/workflows/workflow-defs';
  static WORKFLOW_RUN_STEPS = Routes.baseAddress + '/workflows/workflow-run-steps';

  // =============================== Component =============================== //
  static GET_SHARED_COMPONENETS = Routes.baseAddress + '/inventory/shared-components';
  static GET_COMMON_COMPONENTS_FIELDS = Routes.baseAddress + '/inventory/components/component-fields'
  static COMPONENTS_API = Routes.baseAddress + '/inventory/components';
  static DIGITAL_TWIN_COMPONENT_SETTINGS = Routes.baseAddress + '/inventory/settings/ComponentStatus'

  // =============================== Data Resource =============================== //
  static GET_DATA_RESOURCE =
    Routes.baseAddress + '/data-resource/data-resource';

  // =============================== Scene Definition Document =============================== //
  static SCENE_DEFINITION = Routes.baseAddress + '/canvas/scene-definition';

  // =============================== Annotation 2d =============================== //
  static ANNOTATION_2D = Routes.baseAddress + '/canvas/annotation-2d';

  // =============================== Digital Twin =============================== //
  static DIGITAL_TWIN = Routes.baseAddress + '/account/auth/digital-twin';

  //================================ Sites ======================================//
  static GET_SITE=Routes.baseAddress + '/inventory/sites'

  //================================ FIELD_TEMPLATES ======================================//
  static GET_FIELD_TEMPLATES = Routes.baseAddress + '/templates/fields-template'
  static GET_TEMPLATE_BY_ID = Routes.baseAddress + '/templates/fields-template/'

  // =============================== Label lookup =============================== //
  static LABEL_LOOKUP = Routes.baseAddress + '/canvas/labels';

  // =============================== list by ids =============================== //
  static GET_ASSETS_LIST_BY_IDS = Routes.baseAddress + '/inventory/assets/list-by-ids'
}
