import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-range-slider',
  templateUrl: './custom-range-slider.component.html',
  styleUrls: ['./custom-range-slider.component.scss']
})
export class CustomRangeSliderComponent {
  @Input() rangeValue: number = 0;
  @Output() emittedRangeValue = new EventEmitter();

  ngOnInit(): void {
  }

  fnChangeRange(target: any) {
    let value: number = target.value;
    this.rangeValue = value;
    this.emittedRangeValue.emit(value)
  }
}