import {
  Component,
  AfterViewInit,
  ElementRef,
  Renderer2,
  OnInit,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PermissionService } from './services/permission.service';
import * as bootstrap from 'bootstrap';
// import '../assets/js/config';
// declare var bootstrap: any;
import './config-hsf.js';
import './config-iframe.js';
import { layoutService } from './layout-service';
import { AMPLITEL_ROUTES } from './constants';
@Component({
  selector: 'app-canvas-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'micro-canvas';
  isIframeMode = false;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private permissionService: PermissionService,
  ) {
    this.isIframeMode = AMPLITEL_ROUTES.includes(window.location.pathname);
  }

  ngOnInit(): void {
    this.permissionService.loadPermissions();
    this.setLayout();
  }

  setLayout() {
    if (layoutService.layoutFreeroutes.includes(window.location.pathname)) {
      document.documentElement.style.setProperty(
        '--layout-sideBar-width',
        '0px'
      );
      document.documentElement.style.setProperty(
        '--layout-header-height',
        '44px'
      );
      document.documentElement.style.setProperty(
        '--layout-footer-height',
        '0px'
      );
    } else {
      document.documentElement.style.setProperty(
        '--layout-sideBar-width',
        '44px'
      );
      document.documentElement.style.setProperty(
        '--layout-header-height',
        '44px'
      );
      document.documentElement.style.setProperty(
        '--layout-footer-height',
        '34px'
      );
    }
  }

  ngAfterViewInit() {
    // Event ot listen on route change
    this.router.events.subscribe((event) => {
      // On End of route
      if (event instanceof NavigationEnd) {
        this.renderer.listen('window', 'load', () => {
          this.enableBsTooltip();
        });
      }
    });
  }

  // Enable bootstrap tooltip
  enableBsTooltip() {
    const tooltipTriggerList: any =
      this.elementRef.nativeElement.querySelectorAll(
        '[data-bs-toggle="tooltip"]'
      );
    const tooltipList = [...tooltipTriggerList].map(
      (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
    );
  }
}
