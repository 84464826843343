<div class="filter-dropdown-wrap">
  @if(multiple){
  <div class="tags-wrap">
    @for(val of selected; track val){
    <div class="tag-item">
      <span>{{ val }}</span>
      <img class="icon" [src]="'/icon/close2.svg' | assetUrl" alt="cross" (click)="removeSelected(val)">
    </div>
    }
  </div>
  }

  <div class="input-container">
    <input [readonly]="selectOnly" type="text" #metaFieldText [placeholder]="placeholder" class="dropdown-toggle"
      [value]="multiple ? '' : selected[0] ? selected[0] : metaFieldText?.value"
      (keyup)="filterOptions(metaFieldText.value)"
      (keyup.enter)="onKeyEnter(options[0]); multiple && metaFieldText.value=''" (click)="toggleDropdown()">

    <span class="arrow" (click)="toggleDropdown()">
      <i class="fa up-down-icon" [ngClass]="showDropdown ? 'fa-angle-up': 'fa-angle-down'"></i>
    </span>

    @if(showDropdown){
    <ul class="dropdown-menus">
      @if(allowCreation){
        <li class="add-option" (click)="add()">
          <img class="circular-add-icon"
          [src]="'/icon/circular-add.svg' | assetUrl" alt="add-icon">
          <span class="add-text">Add new {{ label.toLowerCase() }}</span>
        </li>
      }
      @for(option of options; track $index){
      <li class="options" (click)="selectOption(option); metaFieldText.value=''">
        <div class="checkbox-item">
          @if(multiple){
            <input class="checkbox" type="checkbox" [checked]="selected.includes(option)">
          }
          <span>{{ option }}</span>
        </div>
      </li>
      }@empty {
      <div class="options">No record found</div>
      }
    </ul>
    }
  </div>
</div>