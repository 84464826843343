import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, take } from "rxjs";
import { IComponentStatusSettings, ISiteDetailResponse } from "../interfaces";
import { Routes } from "./routes";
import { AMPLITEL_ROUTES, COMPONENT_ASSOCIATION_STATUS_COMPARE } from "../constants";

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(private httpClient: HttpClient) {
    if(!AMPLITEL_ROUTES.includes(window.location.pathname)) this.populateColorCodesForDT();
  }

  digitalTwinSettings: IComponentStatusSettings = {};

  // Array of object to Object of object
  arrayToObject(arr: any[], property: string) {
    const result: any = {};
    for (const element of arr) {
      const key = element[property];
      result[key] = element;
    }
    return result;
  }

  // check whether the object is empty or not
  isObjectEmpty(objectName: any) {
    return (
      objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object
    );
  };

  getSiteById(siteId: string = '') :Observable<ISiteDetailResponse> {
    return this.httpClient.get<ISiteDetailResponse>(`${Routes.GET_SITE}/${siteId}`).pipe(take(1));
  }

  emitEventForTooltipData(siteInfo:any,missionInfo?:any){
    if(siteInfo){
      const customEvent = new CustomEvent("dronos:canvas:tooltipInfo", {
        detail: { site_info:siteInfo},
      });
      window.dispatchEvent(customEvent);
    }
    if(missionInfo){
      const customEvent = new CustomEvent("dronos:canvas:tooltipInfo", {
        detail: { mission_info:missionInfo},
      });
      window.dispatchEvent(customEvent);
    }
  }

  populateColorCodesForDT() {
    this.getDigitalTwinSettings().subscribe((componentSettings) => {
      this.digitalTwinSettings = componentSettings;
    });
  }

  getDigitalTwinSettings() {
    return this.httpClient.get<IComponentStatusSettings>(Routes.DIGITAL_TWIN_COMPONENT_SETTINGS).pipe(take(1));
  }

  // if color code for orphaned is not present, then show as uncategorized
  showUncategorized() {
    return !this.digitalTwinSettings[COMPONENT_ASSOCIATION_STATUS_COMPARE.ORPHANED]
  }
}
