@if(diplayModal){
<div class="property-modal">
  <div class="property-container-layout"
    [ngStyle]="{'--modal-height': propertyModalConfig.height, '--modal-width': propertyModalConfig.width}">
    <div class="property-container-layout-header">
      <div class="d-flex justify-content-between">
        <span class="property-container-layout-header-title">
          Add Property
        </span>
        <span class="property-container-layout-header-close" (click)="onClickClose()">
          <img [src]="'/icon/close.svg' | assetUrl" alt="round">
        </span>
      </div>
      <div class="property-container-layout-header-divider"></div>
    </div>
    <div class="property-container-layout-content">
      <div class="property-container-layout-content-search d-flex align-items-center justify-content-between">
        <input type="text" class="search-box" placeholder="Search Property">
        <button type="button" class="btn search-btn" (click)="onButtonSearch()">
          Search
        </button>
      </div>
      <div class="property-container-layout-content-list">
        <ul *ngFor="let property of propertyList">
          <li class="d-flex align-items-center justify-content-between"
            [ngClass]="selectedProperty === property?._id ? 'active': ''">
            <span>
              <h6 class="property-name">{{property?.propertyName || ''}}</h6>
              <p class="property-desc mb-0">{{property?.propertyDescription || ''}}</p>
            </span>
            <span>
              <button type="button" class="btn select-btn" 
                [ngClass]="selectedProperty === property?._id ? 'active' : ''" (click)="onSelectProperty(property)">
                {{selectedProperty === property?._id ? 'Selected' : 'Select'}}
              </button>
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="property-container-layout-footer">
      <div class="property-container-layout-footer-buttons-groups d-flex align-items-end justify-content-between">
        <button type="button" class="btn custom-prop-btn" (click)="onClickClose()">
          Cancel
        </button>
        <button type="button" class="btn add-btn" (click)="onClickAdd()">
          Add
        </button>
      </div>
    </div>
  </div>
</div>
}
