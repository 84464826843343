import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AssetUrlGlobePipe } from 'projects/annotation-2d/src/app/asset-url-globe.pipe';
import { DOWNLOAD_REPORT_TXT } from 'projects/digital-twin/src/app/shared/constants/shared.const';

export interface IModalData {
  show: boolean,
  state: string,
  title: string,
  subtitle: string,
  download?: boolean
}

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrl: './message-modal.component.scss',
  standalone: true,
  imports:[CommonModule, AssetUrlGlobePipe]
})
export class MessageModalComponent {
  @Input() modalData: IModalData = {
    show: false,
    state: '',
    title: '',
    subtitle: ''
  }
  @Output() closeClick = new EventEmitter()
  @Output() downloadClick = new EventEmitter()
  downloadReportTxt = DOWNLOAD_REPORT_TXT
  
  closeClicked() {
    this.closeClick.emit(this.modalData)
  }

  downloadClicked() {
    this.downloadClick.emit(true)
  }
}
