import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import * as OpenSeadragon from 'openseadragon';
import { Subject, debounceTime } from 'rxjs';
import { ARROW_KEYS, IRESOURCE_TAG } from 'projects/digital-twin/src/app/constants';
import { IframeViewerService, ImageSortingService } from 'projects/digital-twin/src/app/services';
import { IIMAGE_VIEWER_DEFAULT_TAG_OBJECT } from 'projects/digital-twin/src/app/interfaces/iframe.interface';
import * as moment from 'moment-timezone';
import { IDataResourceRecord } from '../../../interfaces';
@Component({
  selector: 'app-custom-image-viewer',
  templateUrl: './custom-image-viewer.component.html',
  styleUrls: ['./custom-image-viewer.component.scss'],
})
export class CustomImageViewerComponent implements OnChanges, OnDestroy {
  /* #region variable initialization/declaration */
  direction: string = '';
  degree: number = 0;
  viewer: any;
  selectedImageIndex: number = 0;
  defaultTagObject!: IIMAGE_VIEWER_DEFAULT_TAG_OBJECT | null;
  filteredThumbnailImageList: any[] = [];
  imageTagDropdownList: IIMAGE_VIEWER_DEFAULT_TAG_OBJECT[] = [];
  isLoading: boolean = true;
  latitude: number = 0;
  longitude: number = 0;
  creationDate: string = '';
  altitude: number = 0;
  imageName: string = '';
  momentTimeZone: any = moment;
  userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  isViewerOpen: boolean = false;
  tilesLoaded: number = 0;
  isViewerLoading: boolean = false;
  dropdownOptions: string[] =[]
  selectedImg: string = '';
  private openHandler: () => void;
  /* #endregion variable initialization/declaration */

  /* #region input/ouput/viewChild decorators */
  @Input() originalImages: IDataResourceRecord[] = [];
  @Input() compressedFullResolutionImages: IDataResourceRecord[] = [];
  @Input() viewType: string = '';
  @Input() thumbnailImages: IDataResourceRecord[] = [];
  @ViewChild('canvasImageSlider', { static: true }) canvasImageSlider!: ElementRef<any>;
  @ViewChild('seadragonViewer', { static: false }) seadragonViewer: ElementRef;
  private mouseWheelDebounceEvent = new Subject<any>();
  /* #endregion input/ouput/viewChild decorators */

  constructor(
    private imageSortingService: ImageSortingService,
    private iframeViewerService: IframeViewerService,
    private cdr: ChangeDetectorRef
  ) {
    // Adjust the debounce time in milliseconds
    this.mouseWheelDebounceEvent.pipe(debounceTime(500)).subscribe((event) => {
      this.updateMainImageSrc(this.filteredThumbnailImageList[this.selectedImageIndex]?.parentResourceId || '');
      this.scrollToView();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.thumbnailImages?.length && this.originalImages?.length && this.compressedFullResolutionImages?.length) {
      this.makeTagList();
      this.defaultTagObject = this.imageTagDropdownList[0] ?? null;
      let filteredThumbnailImageList = this.thumbnailImages.filter(ele => ele?.tags?.includes(this.defaultTagObject?.actualTag ?? ''));
      // sort Thumbnail Images
      this.filteredThumbnailImageList = filteredThumbnailImageList?.length ? this.imageSortingService.sortImages(filteredThumbnailImageList, this.viewType) : [];
      // update main image viewer
      this.updateMainImageSrc(this.thumbnailImages[0]?.parentResourceId ?? '');
      // onEach time focus on the content & selected items:
      let elmViewer = document.getElementById("viewer") as HTMLDivElement;
      elmViewer.focus();
      this.cdr.detectChanges();
    }
  }

  makeTagList() {
    this.thumbnailImages.forEach((ele) => {
      const tagsList: string[] = [...(ele?.tags ?? [])];
      const resourceTagSkippedTagList = tagsList.filter(tl => tl.includes(IRESOURCE_TAG.IMAGE_GROUP_HIERARCHY));
      if (resourceTagSkippedTagList.length) {
        for (let tag of resourceTagSkippedTagList) {
          const splittedTag: string = tag.split(':').pop() ?? '';
          const filterDuplicatedImageTags = (this.imageTagDropdownList?.length && splittedTag) ? this.imageTagDropdownList.filter(imgTag => imgTag?.displayName === splittedTag) : [];
          if (!filterDuplicatedImageTags.length) {
            this.imageTagDropdownList.push({ displayName: splittedTag, actualTag: tag });
            this.dropdownOptions.push(splittedTag)
          }
        }
      }
    });
  }

  private mainImageViewerInit(imageUrl: string) {
    if (this.seadragonViewer) {
      this.isLoading = true;
      this.viewer = this.seadragonViewer.nativeElement;
      this.viewer = OpenSeadragon({
        id: 'seadragon-viewer',
        minZoomImageRatio: 0.2,
        maxZoomPixelRatio: 20,
        zoomInButton: 'zoom-in',
        tileSources: {
          type: 'image',
          url: imageUrl,
        },
      });
      // Attach open event handler to remove loader when the image is loaded
      this.viewer.addHandler('open', () => {
        this.isLoading = false
      });
    }
  }

  private changeMainImageSrc(imageUrl: string) {
    if (!this.viewer) {
      this.mainImageViewerInit(imageUrl)
    } else {
      if (this.viewer.open) this.viewer.close(); // Close the current image
      this.isLoading = false;
      this.isViewerLoading = true;
      if (!imageUrl) return;
      let thumbImageUrl = this.filteredThumbnailImageList[this.selectedImageIndex]?.src || '';
      const tileSource = { type: 'image', url: thumbImageUrl, buildPyramid: false }; // Do not build a tile pyramid for the low-res image
      if (this.openHandler) {
        this.viewer.removeHandler('open', this.openHandler);
      }
      this.viewer.open(tileSource); // Open viewer with new tilesource image;
      this.isViewerOpen = true;
      this.tilesLoaded = 0;
      this.openHandler = () => {
        // Set up the tile source for the high-resolution image
        if (this.isViewerOpen) {
          setTimeout(() => {
            this.loadHighResolution(imageUrl)
          }, 100);
        }
      };
      this.viewer.addHandler('open', this.openHandler);
    }
  }

  loadHighResolution(imageUrl: string) {
    // Close the current image if there is one
    if (this.viewer.isOpen()) this.viewer.close();
    this.isViewerLoading = true;
    this.isViewerOpen = false;
    const highResTileSource = { type: 'image', url: imageUrl };
    // Open the viewer with the high-resolution tile source
    this.viewer.open(highResTileSource);
    this.viewer.addHandler('open', () => {
      // Reset the loading indicator     
    this.isViewerLoading = false;
    });
  }

  // level selection box:
  getSelectedLevelItem(event: any) {
    if(event === this.defaultTagObject?.displayName) return
    this.defaultTagObject = this.imageTagDropdownList?.find(ele=>ele.displayName === event) ?? null
    let filteredThumbnailImageList = this.thumbnailImages.filter(ele => ele?.tags?.includes(this.defaultTagObject?.actualTag ?? ''));
    // sort Thumbnail Images
    this.filteredThumbnailImageList = filteredThumbnailImageList?.length ? this.imageSortingService.sortImages(filteredThumbnailImageList, this.viewType) : [];
    this.selectedImageIndex = 0;
    if (this.filteredThumbnailImageList?.length) this.updateMainImageSrc(this.filteredThumbnailImageList[this.selectedImageIndex]?.parentResourceId || '');
  }

  // update the main image viewer source from thumbnail image source
  updateMainImageSrc(imageId: string = '') {
    if (imageId) {
      const selectedImage = this.compressedFullResolutionImages.find(ele=>ele['parentResourceId'] === imageId)
      if (!selectedImage || this.selectedImg === selectedImage._id ) return
      this.selectedImg = selectedImage?._id;
      this.changeMainImageSrc(selectedImage?.['src']);
      this.updateCompass();
      this.getLatAndLong();
    }
  }

  // update compass degree
  updateCompass() {
    let yawDegree: string = this.filteredThumbnailImageList[this.selectedImageIndex]?.metadata?.exif?.gimbalYawDegree || 0;
    let yawDegreeInt = parseFloat(yawDegree)    
    this.degree = yawDegreeInt ? yawDegreeInt < 0 ? 360 + yawDegreeInt : yawDegreeInt : 0;
  }

  //getLatAndLongandImageDetails
  getLatAndLong() {
    let getLatLongValues: number[][] = this.iframeViewerService.getLattitudeLongitudeFromExif(this.filteredThumbnailImageList[this.selectedImageIndex]?.metadata?.exif);
    this.latitude = Number(getLatLongValues[0]);
    this.longitude = Number(getLatLongValues[1]);
    this.imageName = this.filteredThumbnailImageList[this.selectedImageIndex]?.src.split('?')[0].split('/').pop() || '';
    this.creationDate = this.filteredThumbnailImageList[this.selectedImageIndex]?.metadata?.exif?.dateTimeOriginal || '';
    this.altitude = this.filteredThumbnailImageList[this.selectedImageIndex]?.metadata?.exif?.absoluteAltitudeMeters || '';
  }

  // on image click update the main image viewer
  onImageClick(imageIndex: number) {
    this.selectedImageIndex = imageIndex;
    this.scrollToView();
    this.updateMainImageSrc(this.filteredThumbnailImageList[this.selectedImageIndex]?.parentResourceId || '')
  }

  // on thumbnail image nav click
  onThumbNailNavClick(direction: 'left' | 'right') {
    this.selectedImageIndex = direction == 'right' ? Math.min(this.selectedImageIndex + 1, this.filteredThumbnailImageList.length - 1) : Math.max(this.selectedImageIndex - 1, 0);
    this.scrollToView();
    this.mouseWheelDebounceEvent.next(true);
  }

  // on scroll change images
  onMouseWheel(event: WheelEvent) {
    this.canvasImageSlider.nativeElement.scrollLeft += event.deltaY;
    this.selectedImageIndex = event.deltaY > 0 ? Math.min(this.selectedImageIndex + 1, this.filteredThumbnailImageList.length - 1) : Math.max(this.selectedImageIndex - 1, 0);
    this.scrollToView();
    this.mouseWheelDebounceEvent.next(true);
  }

  scrollToView() {
    let el: Element | null = document.getElementById(`thumbnail-${this.selectedImageIndex}`);
    el?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === ARROW_KEYS.ARROW_LEFT) {
      this.onThumbNailNavClick('left');
    } else if (event.key === ARROW_KEYS.ARROW_RIGHT) {
      this.onThumbNailNavClick('right');
    }
  }

  ngOnDestroy(): void {
    if (this.mouseWheelDebounceEvent) this.mouseWheelDebounceEvent.unsubscribe();
    if (this.openHandler) this.viewer.removeHandler('open', this.openHandler);
  }
}
