@if (displayModal) {
    <div class="component-container">
      <div
        class="component-container-layout"
        [ngStyle]="{
          '--modal-height': componentModalConfig.height,
          '--modal-width': componentModalConfig.width
        }"
      >
        <div class="component-container-layout-header">
          <div class="d-flex justify-content-between">
            <span class="component-container-layout-header-title">
                DELETE COMPONENT
            </span>
            <span
              class="component-container-layout-header-close"
              (click)="closeModal()"
            >
              <img [src]="'/icon/close.svg' | assetUrl" alt="round" />
            </span>
          </div>
          <div class="component-container-layout-header-divider"></div>
        </div>
        <div class="component-container-layout-content">
          <div class="text-center warning-container">
            <img class="p-2" [src]="'/icon/alert.svg' | assetUrl" alt="alert" />
            <h2 class="component-warning-title p-1">Are you sure you want to delete this component {{ componentName }} ?</h2>
            <p class="component-warning-sub-title">You won’t be able to revert this action.</p>
            <div>
                <button class="warning-cancel-btn mr-4"(click)="onCancelClick()">Cancel</button>
                <button class="warning-delete-btn" (click)="onConfirmClick()">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop" (click)="closeModal()"></div>
  }
  