import { Injectable } from "@angular/core";
import { ICameraAxis, IDataResourceRecord, IUniqueIds } from "../interfaces";
import { CommonService } from "./common.service";
import { IMAGE_SORTING_MODE, IRESOURCE_TAG } from "../constants";
import { IIMAGE_VIEWER_DEFAULT_TAG_OBJECT } from "../interfaces/iframe.interface";
import { ImageSortingService } from "./image-sorting.service";
@Injectable({
  providedIn: 'root'
})
export class NearestViewPortSortingService {
  constructor(
    private commonService: CommonService,
    private imageSortingService: ImageSortingService
  ) { }

  bestViewImages(imgsWithRotationAndCenter: any[], cameraPosition: any, cameraDirection: any, angleThresholdDegrees: number,
    distThreshMeters: number, numViewsToReturn: number) {
    const angleFilteredImages = [];
    const filteredImagesToDistance = [];
    for (let image of imgsWithRotationAndCenter) {
      const {rotationMatrix} = image;
      if (rotationMatrix?.length) {
        let directionVector: ICameraAxis = {
          'x': rotationMatrix[2][0],
          'y': rotationMatrix[2][1],
          'z': rotationMatrix[2][2]
        }
        if (this.checkIfAngleBetweenVecsIsWithinThreshold(directionVector, cameraDirection, angleThresholdDegrees)) {
          angleFilteredImages.push(image);
        }
      }
    }
    for (let idx: number = 0; idx < angleFilteredImages.length; idx += 1) {
      const isCameraCenterExists = !!imgsWithRotationAndCenter[idx]?.cameraCenter?.length;
      if (isCameraCenterExists) {
        let imageCameraCenter: ICameraAxis = {
          'x': angleFilteredImages[idx].cameraCenter[0],
          'y': angleFilteredImages[idx].cameraCenter[1],
          'z': angleFilteredImages[idx].cameraCenter[2]
        }
        let dis: number = this.calculateDistanceBetween3DPoints(imageCameraCenter, cameraPosition);
        if (dis < distThreshMeters) {
          filteredImagesToDistance[angleFilteredImages[idx]?.imageName] = dis;
        }
      }
    }
    let distanceBasedSortedImages: String[] = !this.commonService.isObjectEmpty(filteredImagesToDistance) ? this.sortDictionary(filteredImagesToDistance) : [];
    let topFiveImages = (distanceBasedSortedImages.length) ? distanceBasedSortedImages.slice(0, numViewsToReturn) : [];
    return topFiveImages;
  }

  private checkIfAngleBetweenVecsIsWithinThreshold(vec1: ICameraAxis, vec2: ICameraAxis, threshDegrees: number) {
    let dotProduct = vec1.x * vec2.x + vec1.y * vec2.y + vec1.z * vec2.z;
    let vec1Mag = Math.sqrt(vec1.x * vec1.x + vec1.y * vec1.y + vec1.z * vec1.z);
    let vec2Mag = Math.sqrt(vec2.x * vec2.x + vec2.y * vec2.y + vec2.z * vec2.z);
    let cosTheta = (dotProduct / (vec1Mag * vec2Mag));
    let angle = Math.acos(cosTheta) * (180 / Math.PI);
    return (angle < threshDegrees) ? true : false;
  }

  private calculateDistanceBetween3DPoints(point1: ICameraAxis, point2: ICameraAxis) {
    return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2) + Math.pow(point2.z - point1.z, 2));
  }

  private sortDictionary(imgNameToDistanceDict: any) {
    let items = Object.keys(imgNameToDistanceDict).map((key) => { return [key, imgNameToDistanceDict[key]] });
    items.sort((first, second) => { return first[1] - second[1] });
    let keys = items.map((e) => { return e[0] });
    return keys;
  }

  sortingDataResourceImages(thumbnailImages: IDataResourceRecord[], sortedTags: string[]) {
    let sortedTagList: string[] = [...sortedTags];
    let thumbnailImageList: IDataResourceRecord[] = [...thumbnailImages];
    // Initialize an empty object to store sorted data
    let formattedThumnailImagesObject: Record<string, Record<string, any>> = {};
    sortedTagList.length && sortedTagList.forEach((sortTagEle: string) => {
      // Check if formattedThumnailImagesObject does not have the current tagEle key, and create an empty object for it if not
      if (!formattedThumnailImagesObject[sortTagEle]) formattedThumnailImagesObject[sortTagEle] = {};
      let filteredThumbnailTagList: IIMAGE_VIEWER_DEFAULT_TAG_OBJECT[] = [];
      let viewType: string = '';
      switch (sortTagEle) {
        case IRESOURCE_TAG.LOS:
          viewType = IMAGE_SORTING_MODE.SPIN_VIEW;
          break;
        case IRESOURCE_TAG.SV:
        case IRESOURCE_TAG.TV:
          viewType = IMAGE_SORTING_MODE.SIDE_VIEW;
          break;
        default:
          viewType = '';
          break;
      }
      let tagFilteredThumbnailList: IDataResourceRecord[] = thumbnailImageList.length ? thumbnailImageList.filter((thmbEle: IDataResourceRecord) => thmbEle?.tags?.some((tagEle: string) => tagEle === `image_group:${sortTagEle}`)) : [];
      // Iterate through the filtered data and extract certain tags
      tagFilteredThumbnailList.length && tagFilteredThumbnailList.forEach((tagThumbEle: any) => {
        const tagsList: string[] = [...tagThumbEle?.tags] || [];
        const resourceTagSkippedTagList = tagsList.filter(tl => tl.includes(IRESOURCE_TAG.IMAGE_GROUP_HIERARCHY));
        if (resourceTagSkippedTagList.length) {
          for (const tag of resourceTagSkippedTagList) {
            const splittedTag: string = tag.split(':').pop() || '';
            const filterDuplicatedImageTags = (filteredThumbnailTagList.length && splittedTag) ? filteredThumbnailTagList.filter((imgTagEle: IIMAGE_VIEWER_DEFAULT_TAG_OBJECT) => imgTagEle?.displayName === splittedTag) : [];
            // If the tag is not already in the filterDuplicatedImageTags, add it
            if (!filterDuplicatedImageTags.length) {
              filteredThumbnailTagList.push({ displayName: splittedTag, actualTag: tag });
            }
          }
        }
      });
      filteredThumbnailTagList.length && filteredThumbnailTagList.forEach((imgTagEle: IIMAGE_VIEWER_DEFAULT_TAG_OBJECT) => {
        let thumbList: IDataResourceRecord[] = tagFilteredThumbnailList.length ? tagFilteredThumbnailList.filter((tagThumbEle: IDataResourceRecord) => tagThumbEle?.tags && tagThumbEle?.tags.includes(imgTagEle?.actualTag)) : [];
        formattedThumnailImagesObject[sortTagEle][imgTagEle['displayName']] = this.imageSortingService.sortImages(thumbList, viewType);
      });
    });
    let finalThumbnailList: IDataResourceRecord[] = [];
    sortedTagList.length && sortedTagList.forEach((key: string) => {
      // Get the keys and sort them in ascending order
      const keys = Object.keys(formattedThumnailImagesObject[key]).sort();
      // Iterate over the sorted keys
      keys.forEach((nestedKey: string) => {
        finalThumbnailList = [...finalThumbnailList, ...formattedThumnailImagesObject[key][nestedKey]];
      });
    });
    let otherThumbnailList: IDataResourceRecord[] = thumbnailImageList.length ? thumbnailImageList.filter((thmbEle: IDataResourceRecord) => sortedTagList.every((tlEle: string) => thmbEle?.tags && !thmbEle?.tags.includes(tlEle))) : [];
    finalThumbnailList = [...finalThumbnailList, ...otherThumbnailList];
    const loadIds: IUniqueIds = {};
    finalThumbnailList = finalThumbnailList.filter(item => {
      if (!loadIds[item._id]) {
        loadIds[item._id] = true;
        return true; // Include the first occurrence of each unique ID
      }
      return false; // Skip duplicates
    });
    return finalThumbnailList;
  }

  private orderingResourceImagesByTags(thumbnailImages: IDataResourceRecord[], sortedTags: string[]) {
    for (const iterator of thumbnailImages) {
      let itemTags = iterator.tags ? [...iterator.tags] : [];
      const matchingIndexes: number[] = sortedTags.map((element, index) => itemTags.includes(element) ? index : -1).filter(index => index !== -1);
      let tagOrderNo: number = matchingIndexes.length > 0 ? Number(matchingIndexes[0] + 1) : Number(sortedTags.length + 1);
      iterator["tagOrderNo"] = tagOrderNo;
    }
  }
}
