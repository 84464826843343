import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map, mergeMap, take } from 'rxjs';
import { Routes } from './routes'
import { IWorkflowRunRecord, IWorkflowrunListResponse } from '../interfaces/api-response.interface';
import { WORKFLOW_RUN_STATUS } from '../constants';
@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  constructor(private httpClient: HttpClient) { }

  getWorkflowRunsByMissionId = (missionId = ''): Observable<IWorkflowrunListResponse> => {
    const queryParams = `missionId=${missionId}`;
    return this.httpClient.get<IWorkflowrunListResponse>(`${Routes.WORKFLOW_RUNS}?${queryParams}`);
  }

  getWorkflowDefs(): Observable<IWorkflowRunRecord> {
    return this.httpClient.get<IWorkflowrunListResponse>(`${Routes.WORKFLOW_DEFS}`).pipe(
      map((x) => x.records?.[0]),
      map((x) => ({
        ...x,
        status: WORKFLOW_RUN_STATUS.NOT_STARTED,
        workflowSteps: x?.workflowSteps
          .map((_) => ({ ..._, status: WORKFLOW_RUN_STATUS.NOT_STARTED }))
      }) as IWorkflowRunRecord)
    );
  }

  // IWorkflowRunRecord is WorkflowDef Object in this case which is same as IWorkflowRunRecord
  createAndRunWorkflowRun(payload: Partial<IWorkflowRunRecord>) {
    return this.httpClient.post<any>(`${Routes.WORKFLOW_RUNS}`, payload).pipe(
      mergeMap((workflowRun: IWorkflowRunRecord) => {
        return this.httpClient.post(`${Routes.WORKFLOW_RUNS}/${workflowRun.id}/run`, {
          workspaceId: payload?.['workspaceId'],
        })
      })
    );
  }

  updateWorkflowRunSteps = (callerId = '', statusId = '', body: { status: string }): Observable<IWorkflowrunListResponse> => {
    const queryParams = `callerId=${callerId}`;
    return this.httpClient.put<IWorkflowrunListResponse>(`${Routes.WORKFLOW_RUN_STEPS}/${statusId}/status?${queryParams}`, body).pipe(take(1));
  }

  updateWorkflowRun = (callerId = '', statusId = '', body: { status: string }): Observable<IWorkflowrunListResponse> => {
    const queryParams = `callerId=${callerId}`;
    return this.httpClient.put<IWorkflowrunListResponse>(`${Routes.WORKFLOW_RUNS}/${statusId}/status?${queryParams}`, body).pipe(take(1));
  }
}
