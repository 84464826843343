import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {
  private loadedScripts: HTMLScriptElement[] = [];
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) { 
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  // Load scripts to DOM (on demand)
  loadScripts(sources: string[], onLoad: (loadedScripts: any[]) => void) {
    const totalScripts = sources.length;
    let loadedScriptsCount = 0;

    const loadScript = (src: string) => {
      const id = src.split('/').pop();
      if (id && document.getElementById(id)) {
        loadedScriptsCount++;
        loadScript(sources[loadedScriptsCount]);
      } else {
        const script = this.renderer.createElement('script');
        script.src = src;
        script.id = id;
        script.onload = () => {
          loadedScriptsCount++;
          this.loadedScripts.push(script);

          if (loadedScriptsCount === totalScripts) {
            onLoad(this.loadedScripts);
          } else {
            loadScript(sources[loadedScriptsCount]);
          }
          
        };

        this.renderer.appendChild(document.body, script);
      }
    };

    loadScript(sources[0]);
  }

  // Unload scripts by selected script ref or all 
  unloadScripts(scriptRef?: any[]) {
    if (scriptRef) {
      const refIds = scriptRef.map(s => s.id);

      scriptRef.forEach((script) => {
        this.renderer.removeChild(document.body, script);
      });
      this.loadedScripts = this.loadedScripts.filter(s => !refIds.includes(s.id));
    } else {
      this.loadedScripts.forEach((script) => {
        this.renderer.removeChild(document.body, script);
      });
      this.loadedScripts = [];
    }
  }
}
