import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs';

import { Routes } from './routes'

@Injectable({
  providedIn: 'root'
})

export class MemberService {
  constructor(private httpClient: HttpClient){}

  getMembers = () => {
    return this.httpClient.get(Routes.GET_MEMBERS)
    .pipe(
      tap((response: any) => {
        return response;
      }));
  }
}
