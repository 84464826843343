import { Component, Input, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { IBarChartConfig, IBarChartOptions } from '../../../interfaces/charts.interface';

@Component({
  selector: 'app-custom-bar-chart',
  templateUrl: './custom-bar-chart.component.html',
  styleUrls: ['./custom-bar-chart.component.scss']
})
export class CustomBarChartComponent {
  @ViewChild("chart") chart!: ChartComponent;
  public chartOptions: Partial<IBarChartOptions>;

  @Input() chartConfig!: IBarChartConfig;
  listItems: any[] = [];


  constructor() {
    this.chartOptions = {
      yaxis: {
        show: false,
        labels: {
          show: false,
        }
      },
      xaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        }
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: false,
      },
      legend: {
        show: false,
      },
      chart: {
        toolbar: {
          show: false,
        },
        type: 'bar',
        stacked: true,
        stackType: "100%",
        height: '54px',
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '100%',
        },
      },
      fill: {
        opacity: 1,
      },
    };
  }

  ngOnInit(): void {
    this.chartOptions['series'] = [...this.chartConfig?.seriesOptions];
    this.listItems = [...this.chartConfig?.listItems];
  }

  fnIsArrayhasLength(tempValue: any[] = []) {
    return (tempValue && tempValue.length)
  }
}