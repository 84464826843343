import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { TitleCasePipe } from '@angular/common';
import { AssetUrlPipe } from '../app/asset-url.pipe';
import { httpCustomInterceptor } from 'fe-micro-share-components';
import { IframeHttpInterceptor } from './interceptors/iframe.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { AMPLITEL_ROUTES } from './constants';
import { TitlecaseFromDelimitersPipe } from './shared/pipes/titlecase-from-delimiters.pipe';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    SharedModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AMPLITEL_ROUTES.includes(window.location.pathname) ? IframeHttpInterceptor : httpCustomInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    TitleCasePipe,
    AssetUrlPipe,
    TitlecaseFromDelimitersPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
