import { HttpClient } from "@angular/common/http";
import { Injectable, WritableSignal, signal } from "@angular/core";
import { Routes } from "./routes";
import { Observable, lastValueFrom, take } from "rxjs";
import { IComponentListResponse, IComponentRecord } from "../interfaces/api-response.interface";
import { EventTypes, IComponentAssociationFilterStatus } from "../interfaces";
import { NotificationService } from "./notification.service";
import { API_MESSAGE_EVENTS } from "../constants";
import { ICreateComponentPayload } from "../interfaces/component.interface";
import { getQueryByParams } from "../utils/get-query-by-params";
import { CanvasDataService } from "./canvas-data.service";
@Injectable({
  providedIn: 'root'
})
export class ComponentService {
  constructor(
    private httpClient: HttpClient,
    private notificationService: NotificationService,
    private canvasDataService: CanvasDataService
  ) { }

  componentFilters = signal<IComponentAssociationFilterStatus[]>([]);

  getComponentDetailsByComponentId = (componentId: string = ''): Observable<any> => {
    return this.httpClient.get<any>(`${Routes.COMPONENTS_API}/${componentId}`).pipe(take(1));
  }

  getSharedComponentDetailsByComponentId = (componentId: string = ''): Observable<any> => {
    return this.httpClient.get<any>(`${Routes.GET_SHARED_COMPONENETS}/${componentId}`).pipe(take(1));
  }

  getComponentFilters(filters: string[]): WritableSignal<IComponentAssociationFilterStatus[]> {
    if (this.componentFilters().length) { 
      return this.componentFilters;
    }
    this.componentFilters.set(this.canvasDataService.makeFilterStatusObject({ filters }) ?? []);
    return this.componentFilters;
  }

  setComponentFilters(filters: IComponentAssociationFilterStatus[]) {
    this.componentFilters.set(filters);
  }

  // ************************* Recursive api call **************************

  // Get all components
  async getAllComponents({ assetId, pageLimit, nextCursor, isPreSignedUrl }: any): Promise<IComponentRecord[]> {
    const queryParams = getQueryByParams({
      assetId,
      pageLimit,
      nextCursor,
      pre_signed_url: isPreSignedUrl,
    })
    let url: string = `${Routes.COMPONENTS_API}?${queryParams}`;
    return this.fetchComponents(url, []);
  }

  // Get all shared components
  async getAllSharedComponents({ siteId, pageLimit, nextCursor, isPreSignedUrl }: any): Promise<IComponentRecord[]> {
    const queryParams = getQueryByParams({
      siteId,
      pageLimit,
      nextCursor,
      pre_signed_url: isPreSignedUrl,
    })
    let url: string = `${Routes.GET_SHARED_COMPONENETS}?${queryParams}`;
    return this.fetchComponents(url, []);
  }

  // get components from api
  private async fetchComponents(url: string, components: any[]): Promise<IComponentRecord[]> {
    try {
      const response = await lastValueFrom(this.httpClient.get<IComponentListResponse>(url));
      components = components.concat(response.records);
      if (response.meta.nextCursor === null) {
        return components;
      } else {
        const nextUrl = url.includes('nextCursor=') ? url.replace(/nextCursor=[^&]+/, `nextCursor=${response.meta.nextCursor}`) : `${url}&nextCursor=${response.meta.nextCursor}`;
        return this.fetchComponents(nextUrl, components);
      }
    } catch (error) {
      let errorObject: any = error;
      this.notificationService.showToast({ type: EventTypes.error, message: errorObject?.error?.message || API_MESSAGE_EVENTS.GENERIC_ERROR_MESSAGE });
      return components;
    }
  }

  // Create a Component
  createComponent(component: ICreateComponentPayload) {
    let url = Routes.COMPONENTS_API
    return this.httpClient.post<any>(url, component) 
  }

  // get component common fields
  getComponentCommonFields() {
    let url = Routes.GET_COMMON_COMPONENTS_FIELDS
    return this.httpClient.get<any>(url)
  }

  // Update Component Details
  updateComponentDetails(id: string, payload: ICreateComponentPayload) {
    return this.httpClient.patch(Routes.COMPONENTS_API  + '/' + id, payload)
  }

  // Delete Component
  deleteComponentById(id: string) {
    return this.httpClient.delete(Routes.COMPONENTS_API + '/' + id)
  }
}
