import { Injectable } from "@angular/core";
import { AMPLITEL_ROUTES, USER_ROLE_PERMISSIONS } from "../constants";
import { state } from '@app/utility';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  /* #region variable initialization/declaration */
  permissions: string[] = [];
  iframePermissions: string[] = [
    USER_ROLE_PERMISSIONS.CANVAS_OVERVIEW_COMPONENTCHART,
    USER_ROLE_PERMISSIONS.CANVAS_SEGMENTATION_COMPONENT_LISTING,
    USER_ROLE_PERMISSIONS.CANVAS_3DSEGMENTATION_VIEW,
    USER_ROLE_PERMISSIONS.CANVAS_COMPONENTS_LISTING,
    USER_ROLE_PERMISSIONS.CANVAS_3DSEGMENTCOMPONENT_VIEW,
    USER_ROLE_PERMISSIONS.CANVAS_2DANNOTATION_ANNOTATION_VIEW,
    USER_ROLE_PERMISSIONS.CANVAS_DIGITALTWIN_VIEW,
    USER_ROLE_PERMISSIONS.CANVAS_DATASET_LOS_VIEW,
    USER_ROLE_PERMISSIONS.CANVAS_DATASET_TOWERVIEW_VIEW,
    USER_ROLE_PERMISSIONS.CANVAS_DATASET_TOPTODOWN_VIEW,
    USER_ROLE_PERMISSIONS.CANVAS_DATASET_NADIR_VIEW,
  ];
  /* #endregion variable initialization/declaration */

  public loadPermissions() {
    // Setting access rights via obtaining user data from the location store
    const userData = localStorage.getItem('user-data');
    const parsedUserData = userData ? JSON.parse(userData) : {};
    let userPermissions: string[] = parsedUserData?.member?.length ? state.getActiveWorkspaceEntity()?.role?.permissions ?? [] : [];
    const isIframeMode: boolean = AMPLITEL_ROUTES.includes(window.location.pathname);
    // Examining the amplitel routes object to see if the URL exists
    this.permissions = isIframeMode ? this.iframePermissions : userPermissions;
  }

  public hasPermissions(permission: any[] | null = []) {
    if(!permission){
      return true
    }
    // Verify whether certain permissions were included in the list of available permissions.
    return this.permissions.length ? this.permissions.some(item => permission.includes(item)) : false;
  }
}
