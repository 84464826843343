import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription, lastValueFrom, take } from 'rxjs';
import { Routes } from './routes'
import { IDataResourceListResponse, IDataResourceRecord } from '../interfaces/api-response.interface';
import { IGetAllImages } from '../interfaces/iframe.interface';
import { NotificationService } from './notification.service';
import { EventTypes, ICameraRotationMatrix, IDataResourceParams } from '../interfaces';
import { API_MESSAGE_EVENTS, CANVAS_NOTIFY_MESSAGES } from '../constants';
import { CommonService } from './common.service';
import { getQueryByParams } from '../utils/get-query-by-params';
const xml2js = require('xml2js');
@Injectable({
  providedIn: 'root'
})
export class DataResourceService {
  setOfthumbnailDataResource: any[] = [];
  setOfOriginFullDataResource: any[] = [];
  respFinalResult: any = {};
  private dataResourceTagViewSubscription: Subscription | undefined;
  // data resource object
  private dataResourceSubject: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);
  dataResource$: Observable<any> = this.dataResourceSubject.asObservable();

  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    private notificationService: NotificationService,
    private commonService: CommonService
  ) { }

  setLoading(loading: boolean) {
    this.loadingSubject.next(loading);
  }

  getDataResource = (
    params: IDataResourceParams,
  ): Observable<IDataResourceListResponse> => {
    const {
      projectId,
      missionId,
      resourceType,
      tags,
      pageLimit,
      isPreSignedUrl,
    } = params;
    const queryParams = getQueryByParams({
      projectId,
      pre_signed_url: isPreSignedUrl,
      missionId,
      resourceType,
      tags,
      pageLimit,
    });
    return this.httpClient
      .get<IDataResourceListResponse>(
        `${Routes.GET_DATA_RESOURCE}?${queryParams}`,
      )
      .pipe(take(1));
  };
  
  getDataResourceById = (params: any): Observable<IDataResourceListResponse> => {
    let queryParams: string = `id=${params.id}&workspaceId=${params.workspaceId}&pre_signed_url=true`
    return this.httpClient.get<IDataResourceListResponse>(`${Routes.GET_DATA_RESOURCE}/${params.id}?${queryParams}`).pipe(take(1));
  }
  removeDataResourceById = (params: any): Observable<IDataResourceListResponse> => {
    let queryParams: string = `id=${params.id}&workspaceId=${params.workspaceId}`
    return this.httpClient.delete<IDataResourceListResponse>(`${Routes.GET_DATA_RESOURCE}/${params.id}?${queryParams}`).pipe(take(1));
  }

  getDataResourceList() {
    this.dataResourceSubject.getValue();
  }
  // ************************* Recursive api call **************************
  // get all images
  getAllImagesView({
    projectId,
    missionId,
    resourceType,
    tags,
    pageLimit,
    nextCursor,
    isPreSignedUrl,
  }: IGetAllImages): Promise<any[]> {
    const queryParams = getQueryByParams({
      pageLimit: Number(pageLimit),
      pre_signed_url: isPreSignedUrl,
      projectId,
      missionId,
      resourceType,
      tags,
      nextCursor: nextCursor ? String(nextCursor) : '',
    });
    let url: string = `${Routes.GET_DATA_RESOURCE}?${queryParams}`;
    return this.fetchImages(url, []);
  }

  // get images from api
  private async fetchImages(url: string, images: any[]): Promise<any[]> {
    try {
      const response = await lastValueFrom(this.httpClient.get<any>(url));
      images = images.concat(response.records);
      if (response.meta.nextCursor === null) {
        return images;
      } else {
        const nextUrl = url.includes('nextCursor=') ? url.replace(/nextCursor=[^&]+/, `nextCursor=${response.meta.nextCursor}`) : `${url}&nextCursor=${response.meta.nextCursor}`;
        return this.fetchImages(nextUrl, images);
      }
    } catch (error) {
      console.error('Error occurred:', error);
      return images;
    }
  }

  /* #region xml to json conversion related functionalities */
  fetchAllImageJSONData = async (params: IGetAllImages) => {
    try {
      const {
        projectId,
        pageLimit,
        missionId,
        resourceType,
        tags,
        isPreSignedUrl
      } = params
      const queryParams = getQueryByParams({
        projectId,
        missionId,
        resourceType,
        pre_signed_url: isPreSignedUrl,
        tags,
        pageLimit: Number(pageLimit),
      })
      const url: string = `${Routes.GET_DATA_RESOURCE}?${queryParams}`
      const response = await lastValueFrom(this.httpClient.get<IDataResourceListResponse>(url));
      const records: any[] = response?.records ? [...response.records] : [];
      const preSignedUrl: string = records.length ? (records[0]?.storage?.files[0]?.preSignedUrl || '') : '';
      const xmlFileAsText: string = preSignedUrl ? await this.fetchXMLFileAsText(preSignedUrl) : '';
      const convertedJson: any = xmlFileAsText ? await this.xmlToJsonConversion(xmlFileAsText) : {};
      return convertedJson;
    } catch (error) {
      let errorObject: any = error;
      this.notificationService.showToast({ type: EventTypes.error, message: errorObject?.error?.message || API_MESSAGE_EVENTS.GENERIC_ERROR_MESSAGE });
      return {};
    }
  }

  private fetchXMLFileAsText(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url);
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.responseText);
        } else {
          reject(new Error(`${API_MESSAGE_EVENTS.XML_REQUEST_FAILED_STATUS_MESSAGE} ${xhr.status}`));
        }
      };
      xhr.onerror = () => {
        reject(new Error(API_MESSAGE_EVENTS.GENERIC_NETWORK_ERROR_MESSAGE));
      };
      xhr.send();
    });
  }

  private xmlToJsonConversion(xmlString: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const parser = new xml2js.Parser({ explicitArray: false });
      parser.parseString(xmlString, (err: any, result: any) => {
        if (err) {
          console.error(`${API_MESSAGE_EVENTS.XML_TO_JSON_CONVERSION_ERROR_MESSAGE} : `, err);
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  createTwoDimensionalArrayForRotation(matrixData: ICameraRotationMatrix): number[][] {
    if (!this.commonService.isObjectEmpty(matrixData || {})) {
      let rotationMatrixWithoutAccurate: ICameraRotationMatrix | any = {};
      if (matrixData?.hasOwnProperty('Accurate')) {
        const { Accurate, ...rotationMatrix } = matrixData;
        rotationMatrixWithoutAccurate = { ...rotationMatrix };
      } else {
        rotationMatrixWithoutAccurate = { ...matrixData };
      }
      const matrixArray: number[][] = [];
      for (let i = 0; i < 3; i++) {
        const row: number[] = [];
        for (let j = 0; j < 3; j++) {
          const key = `M_${i}${j}`;
          row.push(parseFloat(rotationMatrixWithoutAccurate[key]));
        }
        matrixArray.push(row);
      }
      return matrixArray;
    } else {
      return [];
    }
  }

  cameraCenterArray(cameraCenterData: any): number[] {
    if (!this.commonService.isObjectEmpty(cameraCenterData || {})) {
      return Object.entries(cameraCenterData).filter(([key]) => key !== "Accurate").map(([_, value]: any) => parseFloat(value));
    } else {
      return []
    }
  }
  /* #endregion xml to json conversion */
}
