import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-compass',
  templateUrl: './custom-compass.component.html',
  styleUrls: ['./custom-compass.component.scss']
})
export class CustomCompassComponent {
  @Input() deg:number = 0;
}
