import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notfoundiframecomponent',
  templateUrl: './notfoundiframecomponent.component.html',
  styleUrls: ['./notfoundiframecomponent.component.scss']
})
export class NotfoundiframecomponentComponent {
  @Input() errorComponent: string ;
}
