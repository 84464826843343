<div class="compass">
  <div class="compass-inner">
    <div class="north">N</div>
    <div class="east">E</div>
    <div class="west">W</div>
    <div class="south">S</div>
    <div class="main-arrow" [ngStyle]="{'transform': 'translate(-50%, -50%) rotate(' + deg + 'deg)'}" >
      <div class="arrow-up"></div>
      <div class="arrow-down"></div>
    </div>
  </div>
</div>
<div class="deg-value">
  {{
    deg > 0 && deg < 90 ? 'NE' :
    deg > 90 && deg < 180 ? 'SE' :
    deg > 180 && deg < 270 ? 'SW' :
    deg > 270 && deg < 360 ? 'NW' :
    deg == 0 || deg == 360 ? 'N' :
    deg == 90 ? 'E' :
    deg == 180 ? 'S' :
    deg == 270 ? 'W' : 'N'

  }} {{deg}} &deg;
</div>