import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from '../services/permission.service';
@Directive({
  selector: '[permissionGuard]',
  standalone: true
})
export class permissionGuardDirective {
  @Input('permissionGuard') permissionList: string[] | null = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService
  ) { }
   
  ngOnChanges() {
    const hasPermission: boolean = this.permissionService.hasPermissions(this.permissionList);
    this.viewContainer.clear()
    if (hasPermission) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
