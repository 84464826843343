<div class="chart-wrap my-1">
    <div class="bar-chart">
        <apx-chart [xaxis]="chartOptions.xaxis" [yaxis]="chartOptions.yaxis" [dataLabels]="chartOptions.dataLabels"
            [grid]="chartOptions.grid" [legend]="chartOptions.legend" [series]="chartOptions.series"
            [chart]="chartOptions.chart" [plotOptions]="chartOptions.plotOptions" [fill]="chartOptions.fill">
        </apx-chart>
    </div>

    <div class="ul-list">
        <ul>
            <li *ngFor="let item of listItems">
                <img [src]="item.img" alt="">

                <!-- <div class="outer-circle" [style]="'--circle-color:' + item.backgroundColor">
                    <div class="inner-circle">
                    </div>
                </div> -->
            </li>
        </ul>

        <ul>
            <li *ngFor="let item of listItems">
                {{ item.componet }}
            </li>
        </ul>

        <ul>
            <li *ngFor="let item of listItems">
                {{ item.num }}
            </li>
        </ul>

        <ul>
            <li *ngFor="let item of listItems">
                {{ item.percent }}
            </li>
        </ul>

    </div>
</div>