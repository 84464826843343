import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Routes } from './routes';
import { Observable, take } from 'rxjs';
import { ISiteAttachedAssetEntity } from '../interfaces';
@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  constructor(private httpClient: HttpClient) {}

  getAssetsListByIds = (assetIds: string[]): Observable<ISiteAttachedAssetEntity[]> => {
    const payload = {
      assetIds,
    };
    return this.httpClient
      .post<ISiteAttachedAssetEntity[]>(`${Routes.GET_ASSETS_LIST_BY_IDS}`, payload)
      .pipe(take(1));
  };
}
