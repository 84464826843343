import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '', loadChildren: () => import('./modules/canvas/canvas.module').then(module => module.CanvasModule),
  },
  {
    path: 'amplitel', loadChildren: () => import('./modules/iframe-viewer/iframe-viewer.module').then(module => module.IframeViewerModule),
    data: { mode: 'client', isIframe: true }
  },
  {
    path: 'image-viewer', loadChildren: () => import('./modules/iframe-viewer/iframe-viewer.module').then(module => module.IframeViewerModule),
    data: { mode: 'admin' }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [{provide: APP_BASE_HREF, useValue: '/canvas'}],
  exports: [RouterModule]
})
export class AppRoutingModule { }
