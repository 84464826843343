import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, take } from 'rxjs';
import { Routes } from './routes'
import { ISceneDefinitionDocumentSingleVersionResponse, ISceneDefinitionDocumentVersionListResponse } from '../interfaces/api-response.interface';
import { getQueryByParams } from '../utils/get-query-by-params';
import { removeEmptyValuesFromBody } from '../utils/remove-empty-body-values';
@Injectable({
  providedIn: 'root'
})
export class SceneDefinitionDocumentService {
  constructor(private httpClient: HttpClient) { }

  getSceneDefinitionDocument = (projectId: string = '', missionId: string = '', siteId: string = ''): Observable<ISceneDefinitionDocumentVersionListResponse> => {
    const queryParams = getQueryByParams({
      projectId,
      missionId,
      siteId,
    })
    return this.httpClient.get<ISceneDefinitionDocumentVersionListResponse>(`${Routes.SCENE_DEFINITION}?${queryParams}`).pipe(take(1));
  }

  getSceneDefinitionDocumentById = (sceneId: string = ''): Observable<ISceneDefinitionDocumentSingleVersionResponse> => {
    return this.httpClient.get<ISceneDefinitionDocumentSingleVersionResponse>(`${Routes.SCENE_DEFINITION}/${sceneId}`).pipe(take(1));
  }

  saveSceneDefinitionDocument = (body: ISceneDefinitionDocumentSingleVersionResponse): Observable<ISceneDefinitionDocumentSingleVersionResponse> => {
    const payload = removeEmptyValuesFromBody(body);
    return this.httpClient.post<ISceneDefinitionDocumentSingleVersionResponse>(Routes.SCENE_DEFINITION, payload).pipe(take(1));
  }

  updateSceneDefinitionDocument = (sceneId: string = '', body: ISceneDefinitionDocumentSingleVersionResponse): Observable<ISceneDefinitionDocumentSingleVersionResponse> => {
    return this.httpClient.put<ISceneDefinitionDocumentSingleVersionResponse>(`${Routes.SCENE_DEFINITION}/${sceneId}`, body).pipe(take(1));
  }
}
