import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titlecaseFromDelimiters',
})
export class TitlecaseFromDelimitersPipe implements PipeTransform {
  constructor(private titleCasePipe: TitleCasePipe) {}
  transform(value: string | undefined): string | undefined {
    if (!value) return value;
    return value
      .split(/[_-]/)
      .map((word) => this.titleCasePipe.transform(word.toLowerCase()))
      .join(' ');
  }
}
