import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class CustomValidatorModule {
  // form all fields validation check
  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }
}
