import { Injectable } from "@angular/core";
import { IGetSceneCuboidGeometryParams, ISceneCuboidGeometry } from "../interfaces";
import { CANVAS_VIEWER, Equipment_Type } from "../constants";
import { MeshBasicMaterial , BoxGeometry, CircleGeometry, SphereGeometry, EdgesGeometry, LineBasicMaterial, BufferGeometry, DoubleSide, Matrix4, Vector3, Euler, Object3D, Mesh, Group } from 'three';
@Injectable({
  providedIn: 'root'
})
export class TelcoEquipmentBboxEstimationService {

  // Material and Geometry
  BOUNDING_BOX_MATERIAL = new MeshBasicMaterial({
    color: CANVAS_VIEWER.CUBOID_DEFAULT_COLOR, // color will be changed
    opacity: 0.2,
    transparent: true,
    side: DoubleSide
  })
  BOUNDING_BOX_GEOMETRY = new BoxGeometry(
    CANVAS_VIEWER.CUBOID_DEFAULT_SIZING,
    CANVAS_VIEWER.CUBOID_DEFAULT_SIZING,
    CANVAS_VIEWER.CUBOID_DEFAULT_SIZING,
    CANVAS_VIEWER.CUBOID_DEFAULT_SEGEMENT,
    CANVAS_VIEWER.CUBOID_DEFAULT_SEGEMENT,
    CANVAS_VIEWER.CUBOID_DEFAULT_SEGEMENT
  )
  DOT_GEOMETRY = new CircleGeometry(0.1, 16);
  DOT_MATERIAL = new MeshBasicMaterial()
  DOT_SPHERE_GEOMETRY = new SphereGeometry(0.05, 32, 32)
  wireframeGeometry = new EdgesGeometry(this.BOUNDING_BOX_GEOMETRY);
  LINE_MATERIAL = new LineBasicMaterial();
  POLYGON_LINE_GEOMETRY = new BufferGeometry();

  getCuboidGeometry ({heightMeters, widthMeters, depthMeters, towerBaseCenter, equipmentType, offsets, directionalOrientationAngleDegrees = 0.0, locationOrientationAngleDegrees = 0.0}: IGetSceneCuboidGeometryParams): ISceneCuboidGeometry {
    const defaultRadius = { antennaRadius: 2.0, tjdRadius: 1.75 }
    if (offsets[0] === 0 || offsets[1] === 0) {
      if (equipmentType === Equipment_Type.ANTENNA) {
        [offsets[0], offsets[1]] = this.polarToCartesian(defaultRadius.antennaRadius, locationOrientationAngleDegrees);
      } else {
        [offsets[0], offsets[1]] = this.polarToCartesian(defaultRadius.tjdRadius, locationOrientationAngleDegrees);
      }
    }
    const translation: [number, number, number] = [towerBaseCenter[0] + offsets[0], towerBaseCenter[1] + offsets[1], towerBaseCenter[2] + offsets[2]];
    const scale: [number, number, number] = [widthMeters, depthMeters, heightMeters];
    const directionalOrientationAngleRads: number = (Math.PI * directionalOrientationAngleDegrees) / 180;
    const rotation: Array<Array<number>> = [
      [Math.cos(directionalOrientationAngleRads), Math.sin(directionalOrientationAngleRads), 0],
      [-Math.sin(directionalOrientationAngleRads), Math.cos(directionalOrientationAngleRads), 0],
      [0, 0, 1]
    ]
    return { translation: translation, scale: scale, rotation: rotation };
  }

  private polarToCartesian(radiusMeters: number, angleDegrees: number): [number, number] {
    const angleRadians = angleDegrees * (Math.PI / 180);
    const y = radiusMeters * Math.cos(angleRadians);
    const x = radiusMeters * Math.sin(angleRadians);
    return [x, y]
  }

  createTransformationMatrix({rotation, scale, point}: {rotation: number[][], scale: number[], point: Vector3}): Matrix4 {
    const rotationMatrixWithPos = new Matrix4();
    rotationMatrixWithPos.set(
      rotation[0][0], rotation[0][1], rotation[0][2], point.x,
      rotation[1][0], rotation[1][1], rotation[1][2], point.y,
      rotation[2][0], rotation[2][1], rotation[2][2], point.z,
      0, 0, 0, 1
    );
  
    const scaleMatrix = new Matrix4();
    scaleMatrix.set(
      scale[0], 0, 0, 0,
      0, scale[1], 0, 0,
      0, 0, scale[2], 0,
      0, 0, 0, 1
    );
  
    const transformationMatrix = new Matrix4();
    transformationMatrix.multiplyMatrices(rotationMatrixWithPos, scaleMatrix);
  
    return transformationMatrix;
  }

  infoPopupData: { [id: string]: any } = {};

  calculateComponentInfoPopup({markerList, referencePoint} : {markerList: any, referencePoint: any}) {
    markerList.forEach((marker: any) => {
      const values = this.setValuesToInfoPopupData(marker, referencePoint);
      const id = marker.uuid;
      this.infoPopupData[id] = values;
    })
  }

  isNullVector(vector: number[]) {
    if (!vector || vector.length === 0) return true;
    if (vector[0] == 0 && vector[1] == 0 && vector[2] == 0) return true;
    return false;
  }

  setValuesToInfoPopupData(marker: any, referencePoint: any) { 
    const positionCopy = new Vector3().copy(marker.position);  
    const referencePositionCopy = referencePoint ? new Vector3().copy(referencePoint.position) : new Vector3(0, 0, 0);
    const differenceVector = new Vector3().subVectors(positionCopy, referencePositionCopy);
    
    const width = marker.scale.x.toFixed(3);
    const depth = marker.scale.y.toFixed(3);
    const length = marker.scale.z.toFixed(3);
    let azimuth;
    
    const referenceDirection = marker.component?.properties?.telco_equipment_reference_vector;
    if (!this.isNullVector(referenceDirection)) {
      azimuth = this.calculateAzimuth({trueNorth: [0, 1, 0], refVector: referenceDirection});
    }
    const offsetX = differenceVector.x.toFixed(3);
    const offsetY = differenceVector.y.toFixed(3);
    const offsetZ = differenceVector.z.toFixed(3);
    return {width, depth, length, azimuth, offsetX, offsetY, offsetZ};
  }

  calculateAzimuth({trueNorth, refVector}: {trueNorth: number[], refVector: number[]}) {
    const refVector3 = new Vector3(refVector[0], refVector[1], refVector[2]);
    const trueNorth3 = new Vector3(trueNorth[0], trueNorth[1], trueNorth[2]);
    const crossProduct = new Vector3().crossVectors(trueNorth3, refVector3);
    const dotProduct = refVector3.dot(trueNorth3);
    const length = refVector3.length() * trueNorth3.length();
    // using the cosine rule to measure the angle between two vectors
    let azimuth = Math.acos(dotProduct / length) * (180 / Math.PI);
    // using the right hand rule to determine the orientation of angle
    if (crossProduct.z > 0) azimuth = 360 - azimuth;
    return azimuth.toFixed(2);
  }

  findCenterOfzReferencePolygon(polygonCoordinates: any[] = []) {
    if (polygonCoordinates.length) {
      const center = new Vector3();
      let validPointsCount: number = 0;
      for (const point of polygonCoordinates) {
        if (point.length >= 3 && validPointsCount < 4) {
          center.x += point[0];
          center.y += point[1];
          center.z += point[2];
          validPointsCount++;
        }
      }
      if (validPointsCount === 0) return [0, 0, 0];
      center.x /= validPointsCount;
      center.y /= validPointsCount;
      center.z /= validPointsCount;
      return [center.x, center.y, center.z];
    } else {
      return [0, 0, 0];
    }
  }

  // convert rotation to roatationmatrix
  convertToRotationMatrix(rotationX: number, rotationY: number, rotationZ: number) {
    const euler = new Euler(rotationX, rotationY, rotationZ);
    const rotationMatrix = new Matrix4().makeRotationFromEuler(euler);
    const rotationMatrixArray = [
      [rotationMatrix.elements[0], rotationMatrix.elements[4], rotationMatrix.elements[8]],
      [rotationMatrix.elements[1], rotationMatrix.elements[5], rotationMatrix.elements[9]],
      [rotationMatrix.elements[2], rotationMatrix.elements[6], rotationMatrix.elements[10]],
    ];
    return rotationMatrixArray;
  }

   // get cube face & layer
  getCuboidLayerCenter(cube: Mesh | Object3D, faceIndex: number) {
    //@ts-ignore
    const cubeGeometry = cube.geometry;
    const facesPerLayer = CANVAS_VIEWER.CUBOID_DEFAULT_SEGEMENT * CANVAS_VIEWER.CUBOID_DEFAULT_SEGEMENT * 2; // each layer has x segement with 2 triangles
    // Get the faceIndex 0 to 5
    const layerIndex = Math.floor(faceIndex / facesPerLayer);
    const layerStartIndex = layerIndex * facesPerLayer;
    const layerEndIndex = (layerIndex + 1) * facesPerLayer;
    // Calculate the center point of the clicked layer
    const layerVertices = [];
    for (let i = layerStartIndex; i < layerEndIndex; i++) {
      const vertexIndex1 = cubeGeometry.index.array[i * 3];
      const vertexIndex2 = cubeGeometry.index.array[i * 3 + 1];
      const vertexIndex3 = cubeGeometry.index.array[i * 3 + 2];
      const vertex1 = new Vector3().fromArray(cubeGeometry.attributes.position.array, vertexIndex1 * 3);
      const vertex2 = new Vector3().fromArray(cubeGeometry.attributes.position.array, vertexIndex2 * 3);
      const vertex3 = new Vector3().fromArray(cubeGeometry.attributes.position.array, vertexIndex3 * 3);
      layerVertices.push(vertex1, vertex2, vertex3);
    }
    // Calculate the centroid of the layer vertices
    const layerCentroid = new Vector3();
    for (const element of layerVertices) {
      layerCentroid.add(element);
    }
    layerCentroid.divideScalar(layerVertices.length);
    return { layerIndex, layerCentroid, layerStartIndex, layerEndIndex }
  }

  pointInFace(point: Vector3, vertices: Vector3[]) {
    const v0 = vertices[0], v1 = vertices[1], v2 = vertices[2];
    const d1 = this.sign(point, v0, v1);
    const d2 = this.sign(point, v1, v2);
    const d3 = this.sign(point, v2, v0);
    const hasNeg = (d1 < 0) || (d2 < 0) || (d3 < 0);
    const hasPos = (d1 > 0) || (d2 > 0) || (d3 > 0);
    return !(hasNeg && hasPos);
  }

  sign(p1: Vector3, p2: Vector3, p3: Vector3) {
    return (p1.x - p3.x) * (p2.y - p3.y) - (p2.x - p3.x) * (p1.y - p3.y);
  }

  getFaceNormal(cube: Mesh, directionVector: Mesh | Group) {
    const geometry = new BoxGeometry();
    const matrix = new Matrix4();
    const normal = new Vector3();
    const localPosition = directionVector.position.clone();
    const worldPosition = localPosition.clone();
    matrix.identity().extractRotation(cube.matrixWorld);
    worldPosition.applyMatrix4(cube.matrixWorld);
    let faceIndex = -1;
    const index = geometry.index;
    const positions = geometry.attributes['position'];
    if (index != null) {
      for (let i = 0; i < index.count; i += 3) {
        const a = index.array[i];
        const b = index.array[i + 1];
        const c = index.array[i + 2];
        const v0 = new Vector3().fromBufferAttribute(positions, a);
        const v1 = new Vector3().fromBufferAttribute(positions, b);
        const v2 = new Vector3().fromBufferAttribute(positions, c);
        if (this.pointInFace(localPosition, [v0, v1, v2])) {
          faceIndex = i / 3;
          break;
        }
      }
      if (faceIndex !== -1) {
        const vA = new Vector3().fromBufferAttribute(positions, index.array[faceIndex * 3]);
        const vB = new Vector3().fromBufferAttribute(positions, index.array[faceIndex * 3 + 1]);
        const vC = new Vector3().fromBufferAttribute(positions, index.array[faceIndex * 3 + 2]);
        normal.crossVectors(vC.clone().sub(vA), vB.clone().sub(vA)).normalize();
        normal.applyMatrix4(matrix.makeRotationFromQuaternion(cube.quaternion));
      }
    }
    return normal.negate().clone();
  }
  

  // compare mesh
  compareMesh(mesh1: THREE.Mesh, mesh2: THREE.Mesh): boolean {
    // compare postion(1d array), scale(1d array), rotation (2d array)
    if (!mesh1.position.equals(mesh2.position)) return false;
    if (!mesh1.scale.equals(mesh2.scale)) return false;
    const tolerance: number = 1e-6;
    if (!(Math.abs(mesh1.rotation.x - mesh2.rotation.x) <= tolerance &&
      Math.abs(mesh1.rotation.y - mesh2.rotation.y) <= tolerance &&
      Math.abs(mesh1.rotation.z - mesh2.rotation.z) <= tolerance)) return false
    return true;
  }

  // create point by dot mesh
  createDot(materialColor: number | string) {
    const dotGeometry = this.DOT_SPHERE_GEOMETRY.clone();
    const dotMaterial = this.DOT_MATERIAL.clone();
    dotMaterial.color.set(materialColor);
    return new Mesh(dotGeometry, dotMaterial);
  }
}
