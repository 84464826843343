<div *ngIf="modalData.show" class="message-modal-wrapper">  
  <div  class="message-modal-container">
    <div class="content">
      <div (click)="closeClicked()" class="close-icon">
        <img [src]="'/icons/close.svg' | assetUrl" alt="modal-icon">
      </div>
      <div class="status-img">
        <img *ngIf="modalData.state === 'success'" [src]="'/images/double-tick.svg' | assetUrl" alt="modal-icon">
        <img *ngIf="modalData.state === 'error'" [src]="'/images/error.svg' | assetUrl" alt="modal-icon">
      </div>
      <div class="title">{{ modalData.title }}</div>
      <div class="subtitle">{{ modalData.subtitle }}</div>
      @if (modalData.download) {
        <div class="subtitle" (click)="downloadClicked()"><a href="#">{{ downloadReportTxt }}</a></div>
      }
    </div>
  </div>
</div>
