import { Pipe, PipeTransform } from '@angular/core';
import { CANVAS_COMPONENT, META_PROPERTY_TYPE, NAMING_CONVENTIONS, SITE_ATTACHED_ASSET_META_PROPERTIES } from 'projects/digital-twin/src/app/constants';
import { IComponentMetaProperties, IMetaPropertyFormatter, IMetaPropertyType, ISiteAttachedAssetMetaProperties } from 'projects/digital-twin/src/app/interfaces/constants.interface';
@Pipe({
  name: 'titleCaseConvertorPipe'
})
export class TitleCaseConvertorPipe implements PipeTransform {
  // convert any case to tilte case.
  transform(text: string = '', caseType: string = '', metaPropertyType: string = ''): string {
    if (!text) return '';
    switch (caseType) {
      case NAMING_CONVENTIONS.CAMEL_CASE:
        const componentMetaProperties: IComponentMetaProperties = CANVAS_COMPONENT.COMPONENT_DETAILS_META_PROPERTIES;
        const siteAttachedAssetMetaProperties: ISiteAttachedAssetMetaProperties = SITE_ATTACHED_ASSET_META_PROPERTIES;
        let metaProperties!: IComponentMetaProperties | ISiteAttachedAssetMetaProperties;
        switch (metaPropertyType) {
          case META_PROPERTY_TYPE.COMPONENT:
            metaProperties = { ...componentMetaProperties };
            break;
          case META_PROPERTY_TYPE.ATTACHED_ASSET:
            metaProperties = { ...siteAttachedAssetMetaProperties };
            break
          default:
            break;
        }
        const singleMetaProperty: IMetaPropertyFormatter = Object.values(metaProperties).filter((ele: any) => ele?.actualKey === text)[0] || {};
        if (singleMetaProperty?.actualKey) {
          return singleMetaProperty?.displayName;
        } else {
          const result: string = text.replace(/([A-Z])/g, " $1");
          const finalResult: string = result ? (result.charAt(0).toUpperCase() + result.slice(1)) : '';
          return finalResult ? finalResult.trim() : '';
        }
      default:
        return '';
    }
  }
}
