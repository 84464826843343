
export class layoutService {
  static layoutFreeroutes = [
    '/canvas/amplitel/los',
    '/canvas/amplitel/digital-twin',
    '/canvas/amplitel/from-above',
    '/canvas/amplitel/side-view',
    '/canvas/amplitel/tower-view',
  ];
}
