import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AssetUrlGlobePipe } from 'projects/annotation-2d/src/app/asset-url-globe.pipe';
import { PROPERTY_LIST } from 'projects/digital-twin/src/app/constants';
import { IPropertyList, IPropertyModalConfig, ISceneObjectProperties } from 'projects/digital-twin/src/app/interfaces';

@Component({
  selector: 'app-custom-property-modal',
  templateUrl: './custom-property-modal.component.html',
  styleUrls: ['./custom-property-modal.component.scss'],
  standalone: true,
  imports:[CommonModule, AssetUrlGlobePipe]
})
export class CustomPropertyModalComponent {
  selectedProperty: keyof ISceneObjectProperties;
  diplayModal: boolean = false;
  propertyList: IPropertyList[] = [...PROPERTY_LIST];
  @Input() propertyModalConfig: IPropertyModalConfig = { height: '444px', width: '500px' }
  @Output() onPropertySelect: EventEmitter<keyof ISceneObjectProperties> = new EventEmitter();

  onSelectProperty(selectedProperty: any) {
    this.selectedProperty = selectedProperty?._id || '';
  }

  onOpenModal() {
    this.diplayModal = true;
  }

  oncloseModal() {
    this.diplayModal = false;
  }

  onButtonSearch() { }
  onClickCustomProperty() { }
  onClickAdd() { 
    this.onPropertySelect.emit(this.selectedProperty)
  }
  onClickClose() { 
    this.diplayModal = false;
    this.onPropertySelect.emit(this.selectedProperty)
  }
}
