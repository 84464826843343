import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPropertyModalConfig } from '../../../interfaces';

@Component({
  selector: 'app-custom-component-delete-modal',
  templateUrl: './custom-component-delete-modal.component.html',
  styleUrl: './custom-component-delete-modal.component.scss'
})
export class CustomComponentDeleteModalComponent {

  displayModal = false
  componentName = ''
  @Input() componentModalConfig: IPropertyModalConfig = {
    height: '444px',
    width: '500px',
  };
  @Output() confirmAction: EventEmitter<boolean> = new EventEmitter();
  
  openModal(name: string) {
    this.componentName = name
    this.displayModal = true;
  }

  closeModal() {
    this.displayModal = false;
  }

  onConfirmClick() {
    this.confirmAction.emit(true);
  }
  onCancelClick() {
    this.confirmAction.emit(false);
  }
}
