import { Stage } from 'konva/lib/Stage';
import { Vector3 } from 'three'
import { IDataResourceRecord, IComponentMetadata, IComponentRecord, ISceneObjectProperties, ISceneObject } from './api-response.interface';
import { Layer } from 'konva/lib/Layer';
export interface IAssociationAccordionList {
  accId: string;
  accTargetName: string;
  accHeading: string;
  accLabel: string;
  accStatus: string;
  accStatusColorCode: string;
  accShowStatus: boolean;
  accEllipsePointStatus: boolean;
  accMetaData: IComponentMetadata,
  accComponentType: string,
  componentOrderNo?: number,
  accAssociationBtnStatus: boolean,
  childrenProperties: Array<IAssociationChildProperties>,
  displayEditAndDeletePopup?: boolean,
  templateId: string
}

export interface IEquipmentSegmentation {
  [key: string]: ISegmentationAccordionList;
}
export interface IAssociationChildProperties {
  text: string;
  key: string;
  value: string;
}
export interface ISegmentationAccordionList {
  accId: string;
  accTargetName: string;
  accLabel: string;
  childrenPropertyCount: number;
  childrenProperties: ISegmentationProperties[];
  visible?: boolean;
}
export interface ISegmentationProperties {
  segmentId: string;
  component: IComponentRecord;
  componentId?: string;
  name: string;
  src?: string;
  value: number;
  isTowerLandMarkObj: boolean;
  isMeasurementObj?: boolean;
  hasReferencePoint?: boolean;
  hasReferenceVector?: boolean;
  visible?: boolean;
  properties?: ISceneObjectProperties;
}
export interface IAssetStatus {
  colorCode: string;
  name: string;
}
export interface ICanvasStageProperties {
  id: string;
  width: number;
  height: number;
  scale?: boolean;
}
export interface IThumbnailInstance {
  stage?: Stage | any,
  layer?: Layer | any,
  bgGroup?: any,
}
export interface ICanvasCooridnates {
  title?: string,
  type?: string,
  x: number;
  y: number;
  width: number;
  height: number;
}
export interface ICanvasDataSetItems {
  id: string;
  name: string;
  url: string;
  coorinate: Array<ICanvasCooridnates>;
  stageProperty: ICanvasStageProperties;
  isLoaded: boolean;
}
export interface IResourceImageRecord {
  isSnapshotImage: boolean;
  updatedAt: any;
  gpsLongitude: string;
  gpsLatitude: string;
  _id: string,
  name: string,
  dataResourceId: string,
  resourceTag: string,
  s3Key: string,
  url: string,
  indexKey: number,
  parentResourceId: string,
  record: IDataResourceRecord[],
  fileName?: string,
  imageName: string,
  thumbnailTags?: [],
  displayTag?: string,
  actualTag?: string,
  showTag?: boolean,
  isAnnotated?: boolean,
}
export interface IStageResourceRecord {
  _id: any,
  thumbUrl: any,
  dataResourceId: string,
  type: string,
  originUrl: string,
  compressedUrl: string,
  originFulId: string,
  stageProperty: any,
  isLoaded: boolean,
  verify_checked: boolean,
  verify_removed: boolean,
  validate: boolean,
  annotationRecords: any,
  isThumbnailMode: boolean,
  gpsLatitude: string,
  gpsLongitude: string,
  updatedAt: string,
  fileName: any,
  showAll: boolean,
  imageName: string,
  isSnapshotImage: boolean,
  isCompressed: boolean,
  isVerticed?: boolean,
  diskCache?: boolean,
  thumbnailTags?: [],
  displayTag?: string,
  actualTag?: string,
  showTag?: boolean,
  isAnnotated?: boolean,
}
export interface INewSceneObject {
  id: string;
  type: string;
  translation?: number[];
  scale?: number[];
  rotation?: number[][];
  properties?: {
    [key: string]: any;
  };
  [key: string]: any;
  isOpenVisualGallery?: boolean;
}
export interface ICanvasRectangleBox {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  name?: string,
  id?: string,
  fill?: string;
  stroke?: string;
  strokeWidth?: number;
  shadowBlur?: number
  draggable?: boolean,
  opacity?: number,
  points?: number[],
  [key: string]: any;
}
export interface ICanvasQueryParams {
  assetId: string;
  siteId: string;
  projectId: string;
  missionId: string;
  resourceType: string;
  workspaceId?: string;
}
export interface IPointerCoordinates {
  x?: number,
  y?: number
}
export interface ISaveAnnotationItems {
  type: string,
  dataResourceId: string,
  geometry: IGeometricPolygon,
  confidence: null,
  classLabels?: string[],
  collectionId?: string,
  tags?: string[],
  metadata?: {},
  [key: string]: any;
}
export interface IGeometricPolygon {
  type: string,
  coordinates: Array<[number, number][]>
}
export interface ICommonProgressbar {
  progressName: string;
  progressPercentage: number;
}
export interface ICanvasImageProperty {
  image: any; //value from library
  width: number;
  height: number;
  opacity?: number;
  [key: string]: any;
}
export interface IAnnotationImageResp {
  originImages: IDataResourceRecord[],
  thumbnailImages: IDataResourceRecord[],
  compressedImages: IDataResourceRecord[],
  overAllThumbnailImages: IDataResourceRecord[],
  [key: string]: any;
}
export interface IDynamicComponentProperty {
  text: string;
  key: string;
  value: string;
}
export interface IPropertyModalConfig {
  height?: string;
  width?: string;
}
export interface IPropertyList {
  _id: string;
  propertyName: string;
  propertyDescription: string;
}
export interface IDataResourceParams {
  projectId: string;
  missionId: string;
  resourceType: string;
  tags: string;
  pageLimit: number;
  isPreSignedUrl: boolean;
}
export interface ICameraRotationMatrix {
  M_00: string,
  M_01: string,
  M_02: string,
  M_10: string,
  M_11: string,
  M_12: string,
  M_20: string,
  M_21: string,
  M_22: string,
  Accurate?: string,
}
export interface ICameraAxis {
  x: number,
  y: number,
  z: number
}
export enum Annotation2DControl {
  POLYLINE = 'polyline',
  RECT = 'rect',
  POINT = 'point',
  NO_ACTIVE_CONTROL = '',
}
export enum Annotation2DControlAction {
  RESIZE = 'resize',
  ROTATE = 'rotate',
  VERTICE = 'vertice',
  DELETE = 'delete',
  RESET = 'reset',
  NO_ACTIVE_CONTROL = '',
}
export interface ICompStatusColorCode {
  value: string;
  label: string;
  colorCode: string;
  checked: boolean;
}
export interface IConfirmDialogConfig {
  title?: string,
  message?: string,
  confirmBtnText?: string,
  cancelBtnText?: string
}
export interface IDefaultRadius {
  antennaRadius: number,
  tjdRadius: number,
}
export interface ISceneCuboidGeometry {
  translation: [number, number, number],
  scale: [number, number, number],
  rotation: Array<Array<number>>
}
export interface IGetSceneCuboidGeometryParams { 
  heightMeters: number, 
  widthMeters: number, 
  depthMeters: number, 
  towerBaseCenter: [number, number, number], 
  equipmentType: string,
  offsets: [number, number, number]
  directionalOrientationAngleDegrees: number,
  locationOrientationAngleDegrees: number, 
}
export interface IEstimatedCuboidGeometry {
  translation: number[]
  scale: number[]
  rotation: number[][]
  id: string,
  status: string,
  name?: string
}
export interface IMeshProperties {
  point: Vector3, 
  scale?: number[], 
  rotation?: any[], 
  id?: string, 
  color?: string, 
  component?: ISceneObject 
}
export interface IComponentGroupType {
  type: string,
  name_prefix: string
}
export interface IComponentMetaFilter {
  displayName: string,
  actualValue: string
}
export interface IDefaultKeys {
  NONE: string
}
export interface IPolygonVerticePoints {
  x: number,
  y: number
}
export interface IOverviewAttachedAssetMetaProperties {
  displayName: string;
  actualKey: string;
  actualValue: string;
}
export interface IComponentAssociationFilterStatus {
  value: string,
  label: string,
  colorCode: string,
  checked: boolean
}
export interface ISnapshotRemoveProperties{
  id: string,
  workspaceId: string | null
}

export interface ICanvasUndo {
  component: string, 
  actionType: string, 
  actions: any 
};
export interface ICurrentSceneAssociateOrNotObject {
  isAssociatedScene: boolean;
  isShowDefectDetails: boolean;
  isShowFilter: boolean;
  isShowAssociateOnlyByIcon: boolean;
}
export interface IUniqueIds {
  [key: string]: boolean;
}
export interface ITagResourceGroup {
  [key: string]: IStageResourceRecord[];
}
export interface ICurrentSceneVersion {
  sceneDefinitionDocId: string;
  sceneDefinitionDocName: string;
}
export interface IAnnotationParams {
  projectId: string;
  missionId: string;
  pageLimit: number;
  nextCursor?: string;
  tags?: string | string[];
}
export interface IComponentInputFieldTypes {
  string: 'text'
  number: 'number',
}