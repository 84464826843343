import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {
  transform(items: any[], keys: string[], searchText: string[]): any[] {
    if (!items) return [];
    if (!searchText || !Array.isArray(searchText) || searchText.length === 0) return items;
    let searchVals: string[] = searchText.map(text => text.toLowerCase());
    return items.filter((item: any) => {
      const itemValues = keys.map(key => key.split('.').reduce((obj, k) => obj?.[k], item));
      const itemStr = itemValues.join(' ').toLowerCase();
      return searchVals.some(searchVal =>  itemStr.includes(searchVal.toLowerCase()));
    });
  }
}
