import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { EventTypes, ToastEvent } from 'projects/digital-twin/src/app/interfaces/notification.interface';
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _toastEvents = new Subject<ToastEvent>();  
  toastEvents: Observable<ToastEvent> = this._toastEvents.asObservable();

  constructor() {}

  showToast(events:ToastEvent){
    this._toastEvents.next(events);
  }  
}
