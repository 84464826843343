import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICanvasUndo } from '../interfaces';


@Injectable({providedIn: 'root'})
export class CanvasUndoService {

  actionHistory: ICanvasUndo[] = [];
  constructor() { }
  
  // update the history by current action
  updateHistory(component: string, actionType: string, actions: any) {
    this.actionHistory.push({component, actionType, actions});
    if (this.actionHistory.length > 10) this.actionHistory.shift();
  }

  // get last action
  getLastAction() {
    let action = null;
    if (this.actionHistory.length) action = this.actionHistory[this.actionHistory.length - 1];
    return action;
  }

  // after undo
  undoDone() {
    this.actionHistory.pop();
  }


}