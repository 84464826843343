import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'trimFilter',
  standalone: true
})
export class TrimFilterPipe implements PipeTransform {
  transform(text: string, maxLength: number): string {
    if (text && text.length <= maxLength) {
      return text;
    }
    return text ? text.substring(0, maxLength - 3) + '...' : "";
  }
}
