<div class="image-viewer-wrap" id="viewer" tabindex="0" (keydown)="onKeyDown($event)">
  <div *ngIf="isLoading" class="loader-overlay">
    <div id="loader"></div>
  </div>
  <!-- image view loader only  -->
  <div *ngIf="isViewerLoading" class="spinner-border spin-loader"></div>
  <!-- main image viewer -->
  <div id="seadragon-viewer" #seadragonViewer></div>
  <!-- compass -->
  <!-- <div class="lock-slider-wrap" *ngIf="direction">
    <div class="slider-content">
      <img class="direction-pointer" [src]="'/icon/direction-pointer.svg' | assetUrl" alt="direction-pointer" />
      <ul class="compass-wrap">
        <li *ngFor="let item of reArrangedCompassDirections" [ngClass]="item == direction ? 'active' : ''">
          {{item}}</li>
      </ul>
    </div>
  </div> -->
  <app-custom-compass class="image-compass" [deg]="degree" ></app-custom-compass>

  <!-- slider images -->
  <div class="image-slider-container">
    <!-- Image Info -->
    <div class="slider-image-info">
      <span>{{imageName}}</span>
      <span class="image-info">
        <img class="clock-icon" [src]="'/icon/clock-1.svg' | assetUrl" alt="clock">
        {{ momentTimeZone(creationDate).format("DD-MM-YYYY hh:mm A") }}
        {{ momentTimeZone.tz(creationDate,userTimeZone).zoneName() }}
      </span>
      <span class="image-info">
        <img class="marker-icon" [src]="'/icon/marker-outlined-1.svg' | assetUrl" alt="marker">
        {{ 'N ' + latitude }}, {{ 'E ' + longitude }}
      </span>
      <span class="image-info">
        <img class="altitude-icon" [src]="'/icon/altitude.svg' | assetUrl" alt="altitude">
        {{ altitude ? (altitude + ' (m)') : '' }}
      </span>
    </div>
    <div class="slider-image-list">
      <!-- Level droplist -->
      <div class="level-drop-list">
        <!-- <div class="form-group"> -->
        <div class="col-xs-12">
          <app-dropdown [options]="dropdownOptions" [selected]="[defaultTagObject?.displayName ?? '']"
            [selectOnly]="true" (onSelect)="getSelectedLevelItem($event)" [placeholder]="'Select'">
          </app-dropdown>
        </div>
        <!-- </div> -->
      </div>
      <!-- slider images -->
      <div class="slider-thumbnail-image_list">
        <ul class="slider-image-list_section" id="slider-all-canvas-img" #canvasImageSlider
          (wheel)="onMouseWheel($event)">
          <ng-container class="thumbnail-scroll" *ngFor="let imageItem of filteredThumbnailImageList, let i = index">
            <li id="thumbnail-{{i}}">
              <div class="image-thumb active" [ngClass]="{ 'active' : i == selectedImageIndex }"
                [ngStyle]="{ 'background-image': 'url('+imageItem?.src+')' }" (click)="onImageClick(i)">
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
      <!-- scroll prev next -->
      <button type="button" class="btn btn-outline left-scroll shadow-none btn-transit"
        (click)="onThumbNailNavClick('left')">
        <i class="fa fa-angle-left previous-icon"></i>
      </button>
      <button type="button" class="btn btn-outline right-scroll shadow-none btn-transit"
        (click)="onThumbNailNavClick('right')">
        <i class="fa fa-angle-right next-icon"></i>
      </button>
    </div>
  </div>
  <div class="prev-next-btn-wrap d-flex justify-content-between">
    <button type="button" class="btn btn-outline left-scroll" (click)="onThumbNailNavClick('left')"
      [disabled]="selectedImageIndex === 0">
      <i class="fa fa-angle-left previous-icon"></i>
    </button>
    <button type="button" class="btn btn-outline right-scroll" (click)="onThumbNailNavClick('right')"
      [disabled]="selectedImageIndex === (filteredThumbnailImageList.length - 1)">
      <i class="fa fa-angle-right next-icon"></i>
    </button>
  </div>
</div>