export const IFRAME = {
  PAGE_LIMIT: 20,
  ORIGINAL_TAG: 'orig_full_res',
  THUMBNAIL_TAG: 'thumbnail',
};
export const FROM_ABOVE_VIEW_CONST = {
  zoom: 18,
  scale: 0.8,
  degreetoradian: 180,
  resource_type: 'imageRGB',
  tags: 'orig_full_res,from_above_view',
  pageLimit: 50
}
export const CUSTOM_IMAGE_VIEWER = {
  FORM_LEVEL_SELECTION_OPTION: ['Compound View', 'Full Tower View', 'Level One Antenna Down', 'Level One Antenna Up', 'Level One Antenna View', 'Level Two Antenna Up', 'Level Two Antenna Down', 'Level Two Antenna Up', 'Level Two Antenna View'],
  COMPASS_DIRECTION: ['210', 'SW', '240', '255', 'W', '285', '300', 'NW', '330', '345', 'N', '15', '30', 'NE', '60', '75', 'E', '105', '120', 'SE', '150', '165', 'S', '195']
}
export const IFRAME_ALERT_MSG = {
  NO_MISSION_ID: 'No mission id found'
}
export const IMAGE_SORTING_MODE = {
  SPIN_VIEW: 'spinView',
  SIDE_VIEW: 'sideView',
  GIMBAL_YAW_DEGREE_0: 0,
  GIMBAL_YAW_DEGREE_90: 90,
  GIMBAL_YAW_DEGREE_180: 180,
  GIMBAL_YAW_DEGREE_270: 270,
  GIMBAL_YAW_DEGREE_360: 360,
};
export const VIEWER_TYPE = {
  LINE_OF_SIGHT: 'LINE OF SIGHT VIEW',
  SIDE_VIEW: 'SIDE VIEW',
  TOWER_PHOTO_VIEW: 'TOWER PHOTO VIEW',
  FROM_ABOVE_VIEW: 'FROM ABOVE VIEW',
  DIGITAL_TWIN: 'DIGITAL TWIN VIEW',
  TOKEN_NOTFOUND: "UNAUTHORISED"
}
export const IFRAME_AUTH = {
  AUTH_KEY: "authKey",
  WORKSPACEID: "workspaceId"
}
export const IIMAGE_VIEWER_ROUTING = {
  LINE_OF_SIGHT_VIEWER: { PATH: 'los', NAME: 'Line of Sight Viewer', ICON: '/icon/canvas_subicon1.svg' },
  TOWER_PHOTO_VIEWER: { PATH: 'tower-view', NAME: 'Tower Photo Viewer', ICON: '/icon/canvas_subicon2.svg' },
  SIDE_VIEWER: { PATH: 'side-view', NAME: 'Side Viewer', ICON: '/icon/canvas_subicon3.svg' },
  FROM_ABOVE_VIEWER: { PATH: 'from-above', NAME: 'From Above Viewer', ICON: '/icon/canvas_subicon4.svg' },
}
export const ARROW_KEYS = {
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
}
export const IFRAME_CONST = {
  SCROLL_VALUE: 150,
  MIN_ZOOM_LIMIT: 1.7,
};