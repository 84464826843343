<div
  #toastElement
  class="toast toaster-container fade toast-width mt-2 w-100"
  [ngClass]="type"
  role="alert"
  aria-live="assertive"
  aria-atomic="true"
>
  <div class="custom-msg-container">
      <div class="msg-icon">
        <span class="badge bg-{{ type }}-icon">
          <ng-container [ngSwitch]="type">
            <i class="fa fa-check" *ngSwitchCase="'success'"></i>
            <i class="fa fa-info" *ngSwitchCase="'info'"></i>
            <i class="fa fa-warning fa-2x" *ngSwitchCase="'warning'"></i>
            <i class="fa fa-remove fa-2x" *ngSwitchCase="'error'"></i>
            <i class="fa fa-info-circle" *ngSwitchCase="'primary'"></i>
            <i class="fa fa-info" *ngSwitchCase="'sticky'"></i>
          </ng-container>
        </span>     
    </div>
    <div class="msg-content mt-1">
      <span>{{ message }}</span>
    </div>
  </div>
</div>
