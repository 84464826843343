import { Component, EventEmitter, Input, Output } from '@angular/core';
import { COMPONENT_GROUP_TYPE_LIST } from 'projects/digital-twin/src/app/constants';
import { IComponentGroupType } from 'projects/digital-twin/src/app/interfaces';

@Component({
  selector: 'app-custom-component-type-modal',
  templateUrl: './custom-component-type-modal.component.html',
  styleUrls: ['./custom-component-type-modal.component.scss']
})
export class CustomComponentTypeModalComponent {
  diplayModal: boolean = false;
  componentTypeList: IComponentGroupType[] = COMPONENT_GROUP_TYPE_LIST;
  onTypeSelectResolve: ((value: IComponentGroupType | PromiseLike<IComponentGroupType> | null) => void) | null;
  openModal() {
    this.diplayModal = true;
    return new Promise<IComponentGroupType | null >((resolve) => {
      this.onTypeSelectResolve = resolve;
    });
  }

  closeModal() {
    if (this.onTypeSelectResolve) this.onTypeSelectResolve(null)
    this.diplayModal = false;
  }

  onSelectType(type:IComponentGroupType) {
    if (this.onTypeSelectResolve) this.onTypeSelectResolve(type)
    this.diplayModal = false;
  }
  onCancelClick() {
    this.closeModal();
  }
}
