<div class="progress-wrap" *ngIf="!showOnlyProgressBar; else progressBar">
  <div class="progress-header d-flex align-items-center justify-content-between">
    <span class="extended-text">{{progressName}}</span>
    <span class="percent">{{progressPercentage}}%</span>
  </div>
  <ng-container [ngTemplateOutlet]="progressBar"></ng-container>
</div>

<ng-template #progressBar>
  <ng-container *ngTemplateOutlet="template; context: { $implicit: progressPercentage, showProgressBar: showOnlyProgressBar }"></ng-container>
</ng-template>

<ng-template #template let-value let-showProgressBar="showProgressBar">
  <div [ngClass]="{'px-4 pb-4': showProgressBar}">
    <div class="progress-content">
      <div class="cust-progress-bar" [style]="'--progressbar-width : ' + value + '%'"></div>
      <img class="line" [src]="'/icon/progress-line.svg' | assetUrl"
        [style]="'--line-width : ' + (value - 2) + '%'" />
    </div>
  </div>
</ng-template>
