<div class="error-wrap">
  <div class="image-wrap"></div>
  <div class="gif-image"></div>
  <div class="error-left-text">
    <div class="inner-wrap">
      <div class="inner-text">
        <h1 class="heading">{{errorComponent}}</h1><br>
        <span class="error-sub-text">Not Available</span>
      </div>
    </div>
  </div>
</div>