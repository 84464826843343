import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, take } from 'rxjs';
import { Routes } from './routes'
import { IMissionListResponse, ISingleMissionResponse } from '../interfaces/api-response.interface';
import { getQueryByParams } from '../utils/get-query-by-params';
@Injectable({
  providedIn: 'root'
})
export class MissionService {
  constructor(private httpClient: HttpClient) { }

  getMission(projectId:string = '', assetId: string = '', siteId: string = ''): Observable<IMissionListResponse> {
    const queryParams = getQueryByParams({projectId, assetIds: [assetId], siteId})
    return this.httpClient.get<IMissionListResponse>(`${Routes.GET_MISSION}?${queryParams}`).pipe(take(1));
  }

  getMissionById(missionId: string = ''): Observable<ISingleMissionResponse> {
    return this.httpClient.get<ISingleMissionResponse>(`${Routes.GET_MISSION}/${missionId}`).pipe(take(1));
  }

  updateMissionById(missionId: string, data: object): Observable<ISingleMissionResponse>{
    return this.httpClient.patch<ISingleMissionResponse>(`${Routes.GET_MISSION}/${missionId}`, data).pipe(take(1))
  }
}
