import { IUserRolesAndPermissions } from "../interfaces/permission.interface";

export const USER_ROLE_PERMISSIONS: IUserRolesAndPermissions = {
  CANVAS_OVERVIEW_COMPONENTCHART: "canvas.overview.componentchart",
  CANVAS_SEGMENTATION_COMPONENT_LISTING: "canvas.segmentation.component.listing",
  CANVAS_SEGMENTATION_COMPONENT_EDIT: "canvas.segmentation.component.edit",
  CANVAS_SEGMENTATION_COMPONENT_DELETE: "canvas.segmentation.component.delete",
  CANVAS_DIGITALTWIN_VIEW: "canvas.digitaltwin.view",
  CANVAS_3DSEGMENTATION_CREATE: "canvas.3dsegmentation.create",
  CANVAS_3DSEGMENTATION_VIEW: "canvas.3dsegmentation.view",
  CANVAS_3DSEGMENTATION_EDIT: "canvas.3dsegmentation.edit",
  CANVAS_3DSEGMENTATION_DELETE: "canvas.3dsegmentation.delete",
  CANVAS_3DSEGMENTATION_VALIDATE: "canvas.3dsegmentation.validate",
  CANVAS_3DSEGMENT_STATUS_VIEW: "canvas.3dsegment.status.view",
  CANVAS_3DSEGMENT_STATUS_EDIT: "canvas.3dsegment.status.edit",
  CANVAS_COMPONENTS_LISTING: "canvas.components.listing",
  CANVAS_COMPONENTS_EDIT: "canvas.components.edit",
  CANVAS_COMPONENTS_DELETE: "canvas.components.delete",
  CANVAS_3DSEGMENTCOMPONENT_ASSOCIATE: "canvas.3dsegmentcomponent.associate",
  CANVAS_3DSEGMENTCOMPONENT_DISASSOCIATE: "canvas.3dsegmentcomponent.disassociate",
  CANVAS_3DSEGMENTCOMPONENT_VIEW: "canvas.3dsegmentcomponent.view",
  CANVAS_3DSEGMENTCOMPONENT_EDIT: "canvas.3dsegmentcomponent.edit",
  CANVAS_2DANNOTATION_3DSEGMENTCOMPONENT_REMOVE: "canvas.2dannotation.3dsegmentcomponent.remove",
  CANVAS_2DANNOTATION_3DSEGMENTIMAGE_VIEW: "canvas.2dannotation.3dsegmentimage.view",
  CANVAS_2DANNOTATION_3DSEGMENTIMAGE_REMOVE: "canvas.2dannotation.3dsegmentimage.remove",
  CANVAS_2DANNOTATION_ANNOTATION_CREATE: "canvas.2dannotation.annotation.create",
  CANVAS_2DANNOTATION_ANNOTATION_VIEW: "canvas.2dannotation.annotation.view",
  CANVAS_2DANNOTATION_ANNOTATION_EDIT: "canvas.2dannotation.annotation.edit",
  CANVAS_2DANNOTATION_ANNOTATION_DELETE: "canvas.2dannotation.annotation.delete",
  CANVAS_2DANNOTATION_ANNOTATION_VALIDATE: "canvas.2dannotation.annotation.validate",
  CANVAS_DATASET_LOS_VIEW: "canvas.dataset.los.view",
  CANVAS_DATASET_TOWERVIEW_VIEW: "canvas.dataset.towerview.view",
  CANVAS_DATASET_TOPTODOWN_VIEW: "canvas.dataset.toptodown.view",
  CANVAS_DATASET_NADIR_VIEW: "canvas.dataset.nadir.view",
  CANVAS_SCENE_DEFINITION_VIEW_VERSIONS: "canvas.scenedefinition.versions.view",
  CANVAS_SCENE_DEFINITION_SAVE: "canvas.scene_definition.save",
  CANVAS_SCENE_DEFINITION_SAVE_NEW_VERSION: "canvas.scene_definition.save_new_version",
  CANVAS_SCENE_DEFINITION_VIEW_PROPERTIES: "canvas.scenedefinition.properties.view",
  CANVAS_SCENE_DEFINITION_UPDATE_PROPERTIES: "canvas.scenedefinition.properties.update",
  CANVAS_SCENE_DEFINITION_DELETE_PROPERTIES: "canvas.scenedefinition.properties.delete",
  CANVAS_SCENE_DEFINITION_CREATE_PROPERTIES: "canvas.scenedefinition.properties.create",
  CANVAS_MEASUREMENT_DELETE: "canvas.measurement.delete",
  CANVAS_MEASUREMENT_EDIT: "canvas.measurement.edit",
  CANVAS_MEASUREMENT_CREATE: "canvas.measurement.create",
  CANVAS_MEASUREMENT_VIEW: "canvas.measurement.view",
  CANVAS_MEASUREMENT_LISTING: "canvas.measurement.listing",
  WORKFLOW_RUN: "workflow.run",
  WORKFLOW_RESTART: "workflow.restart",
  WORKFLOW_CANCEL: "workflow.cancel",
  WORKFLOW_VIEW: "workflow.view",
  WORKFLOW_CREATE: "workflow.create",
  WORKFLOW_STEP_SKIP: "workflow.step.skip",
  WORKFLOW_STEP_RESTART: "workflow.step.restart",
  WORKFLOW_STEP_CANCEL: "workflow.step.cancel",
  ADD_COMPONENT: "inventory.components.create",
  EDIT_COMPONENT: "inventory.components.edit",
  DELETE_COMPONENT: "inventory.components.delete"
}
