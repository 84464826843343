import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { Routes } from './routes'
import { ILabel, ILabelResponse } from '../interfaces/api-response.interface';
@Injectable({
  providedIn: 'root'
})
export class LabelLookupService {

  private measurementTypes: BehaviorSubject<any> = new BehaviorSubject<ILabel[] | null>(null);
  measurementTypes$: Observable<ILabel[]> = this.measurementTypes.asObservable();

  constructor(private httpClient: HttpClient) { }

  getlabels(query: string| null = null): Observable<ILabelResponse> {
    const queryParams =  query ? `labelType=${query}` : '';
    return this.httpClient.get<ILabelResponse>(`${Routes.LABEL_LOOKUP}?${queryParams}`).pipe(take(1)); 
  }

  setMeasurementTypes(data: any) {
    this.measurementTypes.next(data)
  }
}
