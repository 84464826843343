// interface & status code:
export const RESPONSE_STATUS = {
  SUCCESS: 'success',
  FAILED: 'failed',
};
export const RESPONSE_STATUS_CODE = {
  SUCCESS: 200,
};
export const DEFAULT_MESSAGE = {
  ERROR: 'Something went wrong',
  SUCCESS: 'Done',
};
export interface IResponse {
  responseMessage?: string;
  responseCode?: number;
  response?: any;
}