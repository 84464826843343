import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { firstValueFrom } from 'rxjs';
import { Routes } from './routes';
import { IGetSignedURL } from '../interfaces/aws.interface';
import { AWS } from '../constants/aws.constant';

@Injectable({
  providedIn: 'root',
})
export class AwsService {
  credentials: any = false;
  type: string = '';
  retryCount: number = 0;

  constructor(private httpClient: HttpClient) {}

  async getSignedURL(obj: IGetSignedURL) {
    try {
      if (this.credentials && obj.type == this.type) {
        const client = new S3Client({
          region: this.credentials.credentials.region,
          credentials: {
            accessKeyId: this.credentials.credentials.accessKeyId,
            secretAccessKey: this.credentials.credentials.secretAccessKey,
            sessionToken: this.credentials.credentials.sessionToken,
          },
        });
        const command = new GetObjectCommand({
          Bucket: this.credentials.credentials.s3Bucket,
          Key: obj.objectKey,
        });

        //@ts-ignore
        const url = await getSignedUrl(client, command, {
          expiresIn: AWS.SIGNEDURL_EXPIRES,
        });
        return url;
      } else {
        throw '';
      }

    } catch (error: any) {
      if (
        (error == '' || error.name === 'InvalidAccessKeyId') &&
        this.retryCount < AWS.CRED_RETRY_COUNT
      ) {
        this.type = obj.type;
        this.retryCount++;
        let URL: any = await this.getCredentials(
          `project_id=${obj.projectId}&mission_id=${obj.missionId}&resource_type=${obj.type}`,
          obj.projectId,
          obj.missionId,
          obj.type,
          obj.objectKey
        );
        return URL;
      } else {
        throw error;
      }
    }
  }
  getCredentials = async (
    query: string,
    projectId: string,
    missionId: string,
    type: string,
    objectKey: string
  ) => {
    try {
      this.credentials = await firstValueFrom(
        this.httpClient.get(`${Routes.GET_AWS_DATA_CREDENTIALS}?${query}`)
      );
      return await this.getSignedURL({ projectId, missionId, type, objectKey });
    } catch (error) {
      throw error;
    }
  };
}
